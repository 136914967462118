import { capitalizeAllWords } from "./stringUtils"
export const getPayeeLink = (payeeHandleId) => {
    return `${window.location.origin}/${payeeHandleId}`
}

export const addressObjectToString = (address, addDirections = false) => {
    if (!address) return ""
    let addressLine = ""
    if (address.line1) addressLine = `${addressLine}${address.line1}, `
    if (address.line2) addressLine = `${addressLine}${address.line2}, `
    if (address.usaCity) addressLine = `${addressLine}${address.usaCity}, `
    if (address.city) addressLine = `${addressLine}${address.city}, `
    if (address.zipCode) addressLine = `${addressLine}${address.zipCode}, `
    addressLine = addressLine ? capitalizeAllWords(`${addressLine} ${address.countryName}`) : ""
    if (addDirections && Boolean(address.directions)) addressLine = `${addressLine}: ${address.directions}, `
    return addressLine
}

export const getHandleFromString = string => {
    if (typeof string !== "string") return ""
    //set all characters to lowercase
    string = string.toLocaleLowerCase()
    //collapse multiple spaces into one dash
    string = string.replace(/\s+/g, '-');
    //remove any characters that are not numbers, letters or hythens
    string = string.replace(/[^a-zA-Z0-9\-]/g, '')
    //collapse multiple hyphens into one
    string = string.replace(/-+/g, '-');
    return string    
} 

export const validateHandle = (handleId) => {
    if (typeof handleId !== "string") return [
        false,
        "handleId must be a string"
    ]
    if (!handleId.trim()) return [
        false,
        "handleId cannot be an empty string"
    ]
    if (handleId.trim().length < 4) return [
        false,
        "handleId must have at least four characters"
    ]
    const regex = /^[a-z0-9-]+$/;

    if (!regex.test(handleId)) return [
        false,
        "handleId can only contain letters, numbers and hyphens"
    ]
    if (handleId[0] === '-') return [
        false,
        "handleId cannot start with a hypthen"
    ]
    if (handleId[handleId.length - 1] === '-') return [
        false,
        "handleId cannot end with a hypthen"
    ]
    return [true]
}