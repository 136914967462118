import React from "react"
import ProductListItem from "../../containers/ProductListItem"
import styles from "./ProductList.module.css"
import {VIEW_TYPE_LIST, VIEW_TYPE_GRID, DISPLAY_TYPE_ROW, DISPLAY_TYPE_TILE} from "../../constants/interface"

const ProductList = ({productList=[], viewType=VIEW_TYPE_LIST}) => {
    let productDisplayType = undefined
    let viewTypeStyle = ""
    if (VIEW_TYPE_LIST === viewType){
        productDisplayType = DISPLAY_TYPE_ROW
        viewTypeStyle = styles.list
    } else if (VIEW_TYPE_GRID === viewType){
        productDisplayType = DISPLAY_TYPE_TILE 
        viewTypeStyle = ""
    }
    return (
        <div className={`${styles.container} ${viewTypeStyle}`}>
            {
                productList.map(p => {
                                    const {
                                        id, imageUrl="", name="", price=0, description="", 
                                        defaultVariantId="", variantsById={}, hasVariants=false,  
                                        payeeId=""
                                    } = p
                                    return (
                                        <div key={id} className={styles.item}>
                                            <ProductListItem 
                                                product={p}
                                                displayType={productDisplayType}
                                            />
                                        </div>
                                    )
                                })
            }
        </div>
    )
}

export default ProductList