import React from "react"
import ImageWithPlaceholder from "../ImageWithPlaceholder"
import { truncateStringWithEllipsis } from "../../utils/stringUtils"
import AmountSmallAfterDecimal from "../../components/AmountSmallAfterDecimal"
import Checkbox from "../../components/CheckBox"

import currency from "currency.js"

const PayeeOrderListItem = ({item={}}) => {
    if (!item) return ""
    const {name="", imageUrl="", price=0, quantity=0, variantDetails=""} = item
    const itemTotalXcd = currency(price).multiply(quantity).toString()
    console.log(item)
    return (
        <div className={"productContainer row"}>
            <div className={"productDetailsContainer row"}>
                <div className={`margin-bottom-05em`}>
                    <ImageWithPlaceholder src={imageUrl} containerClassName="productImageContainer"/>
                </div> 
                <div className={"margin-left-05em"}>
                    <div className="productName display-flex">
                        <div className="margin-right-05em"><span className={`${quantity > 1 ? "badge dark" : ""}`}>{quantity}</span></div>
                        <div>x {truncateStringWithEllipsis(name, 35)}</div>
                    </div>
                    <div className="amount display-flex margin-top-05em">
                        <div className="margin-right-5px">EC$</div> 
                        <div><AmountSmallAfterDecimal amount={itemTotalXcd}/></div>
                        {
                            quantity > 1?
                            <div className="margin-left-5px">/<AmountSmallAfterDecimal amount={price}/> <span className="font-size-12px">each</span></div>
                            :
                            null
                        }
                    </div>
                    <div className="productDescription margin-top-05em">{variantDetails}</div>
                </div>
            </div>
            <div><Checkbox /></div>
        </div>
    )
}

export default PayeeOrderListItem