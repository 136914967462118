import React, {useState, useEffect, useRef, lazy, Suspense} from "react"
import Loading from "../../components/Loading";
import {decodeTicketReceiptQRCodeData, verifyReceiptScanData} from "../../utils/receiptUtils"
import { retryLazy } from "../../utils/applicationUtils";

import withResultModal from "../../hocs/withResultModal"

import {connect} from "react-redux"
import {bindActionCreators} from "redux";
import * as actions from "../../actions"
import ErrorBoundary from "../../components/ErrorBoundary";
// Wrap your lazy loading with retry logic
const QRCodeScanner = lazy(() =>
  retryLazy(() => import("../../components/QRCodeScanner"))
);

const TicketEntryScanner = ({
    payeeProductId="",
    payeeId="",
    setResult=()=>{},
    setResultMessage=()=>{},
    setResultOptions=()=>{},
    receipts,
    actions
}) => {
    //store the receipt scan data
    const [receiptScanData, setReceiptScanData] = useState(null)
    const scanInProgressRef = useRef(false); // Ref to block scanning
    //find the last date the the receipts were loaded
    const receiptStats = receipts &&
                     receipts.loaded &&
                     receipts.loaded.payeeProductIds &&
                     receipts.loaded.payeeProductIds[payeeProductId] &&
                     typeof receipts.loaded.payeeProductIds[payeeProductId] === "object"
                     ?
                     receipts.loaded.payeeProductIds[payeeProductId]
                     :
                     {}
    const {lastCreatedAt=0, lastUpdatedAt=0, lastLoadedAt=0} = receiptStats
    //listen to the receipts created after the receipt load product 
    const [createdReceiptsListener, setCreatedReceiptsListener] = useState(()=>()=>{})
    //listen to the receipts updated/verified after the last load for this product 
    const [updatedReceiptsListener, setUpdatedReceiptsListener] = useState(()=>()=>{})
    //load all receipts for this product, on mount
    const listenToPayeeProductReceipts = async () => {
        //listen to products
        actions.fetchSaveReceiptsForPayeeProduct(payeeProductId, payeeId)
        if (lastCreatedAt){
            //listen to any receipts created after mounting
            const newCreatedReceiptsListener = await actions.fetchSubscribeToCreatedReceiptsForPayeeProduct(payeeProductId, payeeId, lastCreatedAt)
            setCreatedReceiptsListener(() => newCreatedReceiptsListener)
        }
        if (lastUpdatedAt) {
            //listen to any receipts updated/verified after mounting
            const newUpdatedReceiptsListener = await actions.fetchSubscribeToUpdatedReceiptsForPayeeProduct(payeeProductId, payeeId, lastUpdatedAt)
            setUpdatedReceiptsListener(() => newUpdatedReceiptsListener)
        }
    }
    //load receipts on mount
    useEffect(() => {
        listenToPayeeProductReceipts()
        return () => {
            if (typeof createdReceiptsListener === "function") createdReceiptsListener()
            if (typeof updatedReceiptsListener === "function") updatedReceiptsListener()
        }
    }, [
        payeeProductId,
        lastLoadedAt
    ])
    
    const checkReceiptScanData = () => {
        console.log("beginning check receipt")
        const {success, result, resultMessage, verificationId=""} = verifyReceiptScanData(
            receiptScanData,
            receipts,
            payeeId,
            payeeProductId,
        )
        if (success){
            console.log(`SUCCESS ${verificationId}`)
            //verify the receipt in the DB and redux, so it cannot be reused
            actions.fetchVerifyReceipt(
                receiptScanData.receiptId, 
                receiptScanData.id,
                verificationId
            )
        }
        //display result
        setResult(result)
        setResultMessage(resultMessage)
        //reset the camera when the result screen is closed
        setResultOptions({
            onClose: ()=>{
                console.log(`closing ${verificationId}`);
                setReceiptScanData(null);
                scanInProgressRef.current = false; // Allow scanning again
            }
        })
    }
    //process scan data after scanning the receipt
    useEffect(() => {
        if (receiptScanData){
            checkReceiptScanData()
        }
    }, [receiptScanData])
    return (
        <div>
            <div className="h2 text-align-center margin-bottom-05em display-flex justify-content-center align-items-center" >
               Scan Ticket QR Code
            </div>
            <div className="display-flex justify-content-center align-items-center margin-bottom-05em">
                <ErrorBoundary
                    errorHeading="Sorry, we could not load the scanner. Check your internet connection and retry"
                    errorContext="TicketEntryScanner Lazy-Loading"
                >
                <Suspense fallback={<Loading />}>
                    <div style={{
                        height: "100%",
                        width: "fit-content"
                    }}>
                        <QRCodeScanner 
                            onScan={data => {
                                if (!scanInProgressRef.current) {
                                    console.log("about to set scan data");
                                    scanInProgressRef.current = true; // Block further scans immediately
                                    setReceiptScanData(decodeTicketReceiptQRCodeData(data));
                                }
                            }}
                            isActive={!receiptScanData} //pause or restart the camera
                        />
                    </div>
                </Suspense>
                </ErrorBoundary>
            </div>
        </div>
    )
}

const mapStateToProps = state => ({
    receipts: state.receipts
})

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(withResultModal(TicketEntryScanner))