import React from "react";
import ActivityListItem from "../ActivityListItem"
import Icon from "../Icon";

import AmountSmallAfterDecimal from "../AmountSmallAfterDecimal";

import {
    PAYEE_ACTIVITY_TYPE_INVOICE_PAID, 
    PAYEE_ACTIVITY_TYPE_INVOICE_PARTIALLY_PAID,
    PAYEE_ACTIVITY_TYPE_ONE_TIME_PAYMENT_RECEIVED,
    PAYEE_ACTIVITY_TYPE_PAYEE_ORDER_PAYMENT_RECEIVED,
    PAYEE_ACTIVITY_TYPE_DISBURSEMENT_COMPLETED
} from "../../constants/payeeActivty"
import {numberToCurrencyString} from "../../utils/currencyUtils"

const MerchantActivityListItem = ({
    payeeActivity={}, 
    showRight=true,
    onClick=undefined,
}) => {
    const {title="", reason="", activityType="", totalXcd=0, endingBalance=0, } = payeeActivity
    //show a different badge style based on the invoice's current status
    let icon = ""
    let iconBackgroundColor = ""
    let isCredit = false
    if (activityType === PAYEE_ACTIVITY_TYPE_INVOICE_PAID){
        icon = "receipt"
        iconBackgroundColor = "var(--credit-green)"
        isCredit = true
    } else if (activityType === PAYEE_ACTIVITY_TYPE_INVOICE_PARTIALLY_PAID){
        icon = "receipt"
        iconBackgroundColor = "var(--dark-warning-yellow)"
        isCredit = true
    } else if (activityType === PAYEE_ACTIVITY_TYPE_ONE_TIME_PAYMENT_RECEIVED){
        icon = "monetization-on"
        iconBackgroundColor = "var(--credit-green)"
        isCredit = true
    } else if (activityType === PAYEE_ACTIVITY_TYPE_PAYEE_ORDER_PAYMENT_RECEIVED){
        icon = "shopping-cart"
        iconBackgroundColor = "var(--credit-green)"
        isCredit = true
    } else if (activityType === PAYEE_ACTIVITY_TYPE_DISBURSEMENT_COMPLETED){
        icon = "payment"
        iconBackgroundColor = "var(--secondary)"
        isCredit= false
    }
    const amountStyle = isCredit ? {color: "var(--credit-green)"} : {}
    return (
        <ActivityListItem 
            onClick={onClick}
            left={
                <div style={{"backgroundColor": iconBackgroundColor}} className="innerIconContainer">
                    <Icon icon={icon}/>
                </div>
            }
            right={showRight ? <div style={amountStyle} className={`amount h3`}>{isCredit ? "+": "-"}$<AmountSmallAfterDecimal amount={totalXcd}/></div> : ""}
            title={`$${numberToCurrencyString(payeeActivity.totalReceivedXcd)} ${title}`}    
            reason={reason}
            details={ <div>Ending balance: ${numberToCurrencyString(endingBalance)}</div>}
        />
    )
}

export default MerchantActivityListItem