import React, {useState} from "react"

import DataTable from "../../components/DataTable";
import AmountSmallAfterDecimal from "../../components/AmountSmallAfterDecimal";
import DropdownMenu from "../../components/DropdownMenu";
import ResultModal from "../../components/ResultModal"

import PayeeOrderItemList from "../../containers/PayeeOrderItemList"
import Modal from "../../components/Modal";
import Icon from "../../components/Icon";

import {shortenReference} from "../../utils/interfaceUtils";
import {copyToClipboard} from "../../utils/interactionUtils";
import {timestampToDateString} from "../../utils/datetimeUtils";
import {countCartItems} from "../../utils/cartUtils"

import {MESSAGE_TYPE_EMAIL, TEMPLATE_TYPE_PAYDM_SENDER_PAYEE_ORDER_CONFIRMATION_EMAIL} from "../../constants/messages"
import {RESULT_SUCCESS, RESULT_WARNING} from "../../constants/results"

import {connect} from "react-redux"
import {bindActionCreators} from "redux";
import * as actions from "../../actions"
import {v4 as uuid4} from 'uuid'

const PayeeOrderTable = ({
                          title = "Orders",
                          payeeName = "",
                          emptyText = "No Orders",
                          payeeOrdersArray = [],
                          actions
                      }) => {
    payeeOrdersArray = payeeOrdersArray.map(p => ({
        ...p,
        customerName: p.payerData ? p.payerData.name : "",
        phoneNumber: p.payerData ? p.payerData.phoneNumber : "",
        email: p.payerData ? p.payerData.email : "",
    }))
    //display the order items list
    const [itemListPayeeOrderId, setItemListPayeeOrderId] = useState("")
    //resend confirmation email to customers
    const [confirmationEmailResult, setConfirmationEmailResult] = useState(false)
    const [confirmationEmailResultMessage, setConfirmationEmailResultMessage] = useState("")
    const resendConfirmationEmail = async payeeOrder => {
        const {paidByPayerName="", reference="", payeeId="", payerData={}, amountPaidXcd=0} = payeeOrder
        if (!window.confirm(`Are you sure you want to resend the order confirmation email to ${paidByPayerName} for Order #${shortenReference(reference)}?`)) return
        actions.toggleLoading(true)
        await actions.fetchCreateMessage(
            uuid4(),
            MESSAGE_TYPE_EMAIL,
            TEMPLATE_TYPE_PAYDM_SENDER_PAYEE_ORDER_CONFIRMATION_EMAIL,
            {email: payerData.email},
            {payeeOrderId: payeeOrder.id},
            `RESENT Payee order confirmed: For EC$${amountPaidXcd} to ${payeeName} by ${paidByPayerName} - ${reference}`,
            payeeId,
            () => {
                setConfirmationEmailResult(RESULT_SUCCESS)
                setConfirmationEmailResultMessage(`You've resent the confirmation email to ${paidByPayerName}`)
            },
            () => {
                setConfirmationEmailResult(RESULT_WARNING)
                setConfirmationEmailResultMessage(`Failed to send email. Check your internet connection and retry`)
            }
        )
        actions.toggleLoading(false)
    }
    return (
        <>
            <DataTable
                dataArray={payeeOrdersArray}
                fields={[
                    {
                        id: 'reference',
                        header: 'Reference',
                        transformFunction: reference => <a className="actionText"
                                                        onClick={() => copyToClipboard(shortenReference(reference))}>{shortenReference(reference)}</a>
                    },
                    {
                        id: 'customerName',
                        header: "Name"
                    },
                    {
                        id: 'lineItemsById',
                        header: 'Items',
                        hideSort: true,                                                                 
                        transformFunction: (lineItemsById, id, i, o) => <button className="button small secondary" onClick={() => setItemListPayeeOrderId(id)}>View {countCartItems(o)} items</button>
                    },
                    {
                        id: 'totalXcd',
                        header: 'Total',
                        transformFunction: totalXcd => <div className="font-weight-600">EC$<AmountSmallAfterDecimal
                            amount={totalXcd}/></div>
                    },
                    {
                        id: 'currentStatus',
                        header: 'Status',
                        // transformFunction: currentStatus => <InvoiceBadge currentStatus={currentStatus}/>
                    },
                    {
                        id: 'phoneNumber',
                        header: "Phone"
                    },
                    {
                        id: 'email',
                        header: "Email"
                    },
                    {
                        id: 'paidAt',
                        header: 'Paid At',
                        transformFunction: paidAt => timestampToDateString(paidAt, "EEE, dd MMMM 'at' t")
                    },
                    // {
                    //     id: 'id', //quickAction
                    //     header: "",
                    //     hideSort: true,
                    //     transformFunction: (id, key, i, invoice) => <InvoiceQuickActionButton invoice={invoice}
                    //                                                                           payeeHandleId={payeeHandleId}
                    //                                                                           payeeName={payeeName}
                    //                                                                           />
                    // },
                    {
                        id: 'id', //actions
                        header: "",
                        hideSort: true,
                        transformFunction: (id, key, i, payeeOrder) => <DropdownMenu
                                                                        menuItems={[
                                                                            {
                                                                                name: "Resend Confirmation Email",
                                                                                label: "Resend Confirmation Email",
                                                                                onClick: () => resendConfirmationEmail(payeeOrder),
                                                                            }
                                                                        ]}
                                                                        MenuIcon={
                                                                            <div className={`iconContainer transparent`}>
                                                                                <Icon icon="more-vert"/>
                                                                            </div>
                                                                        }
                                                                    />
                    }
                ]}
                title={title}
                emptyText={emptyText}
                defaultSortBy="paidAt"
                searchFields={["reference", "customerName", "phoneNumber", "email", "currentStatus",]}
            />
            {
                itemListPayeeOrderId ?
                <Modal
                        isOpen={Boolean(itemListPayeeOrderId)}
                        closeModal={() => setItemListPayeeOrderId("")}
                        className="height-fit-content max-height-100pct"
                        overlayClassName="flex-center"
                        closeOnOverlayClick={true}
                        showCloseButton={true}
                    >
                        <PayeeOrderItemList payeeOrderId={itemListPayeeOrderId}/>
                    </Modal>
                :
                null
            }
            {
                confirmationEmailResult ? 
                <ResultModal 
                    result={confirmationEmailResult}
                    autoClose={confirmationEmailResult === RESULT_SUCCESS}
                    message={confirmationEmailResultMessage}
                    onClose={() => {
                        setConfirmationEmailResult(false)
                        setConfirmationEmailResultMessage("")
                    }}
                    autoCloseTimeout={2000}
                />
                    : 
                null
            }
        </>
    )
}

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch)
});

export default connect(null, mapDispatchToProps)(PayeeOrderTable)