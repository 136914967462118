import {DEVELOPMENT, PRODUCTION} from "../constants/environment"
import {selectedEnvironment, useBackendEmulator} from "./index"

import {initializeApp} from 'firebase/app';
import { getFirestore, connectFirestoreEmulator } from "firebase/firestore";
import { getFunctions, connectFunctionsEmulator } from "firebase/functions";
// import { connectAuthEmulator, getAuth } from "firebase/auth"
import {getAnalytics, logEvent} from 'firebase/analytics'
import { initializeAppCheck, ReCaptchaEnterpriseProvider } from "firebase/app-check";

const region = 'us-central1'
const environments = {
  [PRODUCTION]:{
    apiKey: "AIzaSyCix7xda5ZGT3u6yjYYq2qU6GAo8pfTxNY",
    authDomain: "shop-dm.firebaseapp.com",
    databaseURL: "https://shop-dm.firebaseio.com",
    projectId: "shop-dm",
    storageBucket: "shop-dm.appspot.com",
    messagingSenderId: "418956602742",
    appId: "1:418956602742:web:20227e2587bd496874de57",
    measurementId: "G-ZMXN6K9DS7",
    appCheckSiteKey: "6Lf9b_cpAAAAAC2dr75IEvCjLNZxxDBQMo2kntag"
  }, 
  [DEVELOPMENT]: {
    apiKey: "AIzaSyBXhF-7L9aFzTa6MzMbEIMgr0xs635OcwE",
    authDomain: "shop-dm-dev.firebaseapp.com",
    databaseURL: "https://shop-dm-dev.firebaseio.com",
    projectId: "shop-dm-dev",
    storageBucket: "shop-dm-dev.appspot.com",
    messagingSenderId: "731026379532",
    appId: "1:731026379532:web:d1480c0164dbd319f3ef29",
    measurementId: "G-W25ZS79RH3",
    appCheckSiteKey: "6Ldam_YpAAAAAPeYJ5JRypI2m2U3QGO3afV3fBw6"
  }
}

export const config = environments[selectedEnvironment]

export let baseUrl 
export let firebaseApp
export let analytics
let appCheck
// self.FIREBASE_APPCHECK_DEBUG_TOKEN = true; /* eslint-disable-line no-restricted-globals */
export const logAnalyticsEvent = (eventName, eventData) =>{
  if (process.env.NODE_ENV === 'production'){
    logEvent(analytics, eventName, eventData)
  } else {
    console.log('mocked analytics logEvent, name', eventName, 'data', eventData)
  }
}
export const projectName = selectedEnvironment === PRODUCTION ? "shop-dm" : "shop-dm-dev"
const configureFirebase= () => {
  // Initialize Firebase
  firebaseApp = initializeApp(config);
  if (process.env.NODE_ENV === 'production'){
    analytics = getAnalytics(firebaseApp)
  }
  // Create a ReCaptchaEnterpriseProvider instance using reCAPTCHA Enterprise
  // site key and pass it to initializeAppCheck().
  appCheck = initializeAppCheck(firebaseApp, {
    provider: new ReCaptchaEnterpriseProvider(config.appCheckSiteKey),
    isTokenAutoRefreshEnabled: true // allow auto-refresh.
  });
  //set up the base url, used to make fetch https requests to the server
  baseUrl = `https://${region}-${config.projectId}.cloudfunctions.net/`

  if (window.location.hostname === 'localhost' && useBackendEmulator) {
    /**CONNECT TO EMULATOR **/
    const firestore = getFirestore(firebaseApp)
    connectFirestoreEmulator(firestore, "localhost", 8080);

    const functions = getFunctions(firebaseApp)
    connectFunctionsEmulator(functions, "localhost", 5001)
    // const auth = getAuth();
    // connectAuthEmulator(auth, "http://localhost:9099");

    //connect to the V1 web API in the emulator
    baseUrl = `http://localhost:5001/${projectName}/${region}/`
    /**END CONNECT TO EMULATOR */
  }
  
}

export const vapidKey = selectedEnvironment === PRODUCTION ? "BNV8mgsd46Od7Vj4wuiDKDotk_p_zHH8cFtJtdf6pINZDOi2t4fhRK8PWGEYuZEmFJUxJ-zD9wXYZRIxbBSKdls" : "BEiqAFxwX6tyhHmQcBZSotRzuMsmFtIp7UHQntVckfFpwkgfRe9Tt5e_lGGMtsvcy3dRp1bNou_a0nESV8cWzq0"
export default configureFirebase;