//message types
export const MESSAGE_TYPE_EMAIL = 'MESSAGE_TYPE_EMAIL'

//template types
export const TEMPLATE_TYPE_PAYDM_SENDER_PAYEE_ORDER_CONFIRMATION_EMAIL = 'TEMPLATE_TYPE_PAYDM_SENDER_PAYEE_ORDER_CONFIRMATION_EMAIL'

//message statuses
export const MESSAGE_STATUS_CANCELLED = 'MESSAGE_STATUS_CANCELLED'
export const MESSAGE_STATUS_SUCCESSFUL = 'MESSAGE_STATUS_SUCCESSFUL'
export const MESSAGE_STATUS_FAILED = 'MESSAGE_STATUS_FAILED' 
export const MESSAGE_STATUS_PENDING = 'MESSAGE_STATUS_PENDING'