import React, {useState, useEffect} from "react";
import ListByDate from "../../components/ListByDate";
import Icon from "../../components/Icon";
import InvoiceActivityListItem from "../../components/InvoiceActivityListItem";

import { TEMPORARY_NON_LIVE_VISIBILITY } from "../../constants/interface"
import useScreenRefresh from "../../hooks/useScreenRefresh"

import {connect} from "react-redux"
import {bindActionCreators} from "redux";
import * as actions from "../../actions"

const PendingInvoicesDateList = ({payeeId, payeeHandleId="", payeeName="", title="Pending Invoices", invoices, actions}) => {
    //listen to invoices that are not yet closed
    const [openInvoiceListener, setOpenInvoiceListener] = useState(()=>()=>{})
    //listen to invoices that have been recently closed
    const [recentlyClosedInvoiceListener, setRecentlyClosedInvoiceListener] = useState(()=>()=>{})
    //listen to open and recently closed invoices anytime the payee changes
    const listenToInvoices = async () => {
        const newOpenInvoiceListener = await actions.fetchSubscribeToOpenPayeeInvoices(payeeId)
        setOpenInvoiceListener(() => newOpenInvoiceListener)
        const newRecentlyClosedInvoiceListener = await actions.fetchSubscribeToRecentlyClosedPayeeInvoices(payeeId)
        setRecentlyClosedInvoiceListener(() => newRecentlyClosedInvoiceListener)
    }
    useEffect(() => {
        listenToInvoices()
        return () => {
            if (typeof openInvoiceListener === "function") openInvoiceListener()
            if (typeof recentlyClosedInvoiceListener === "function") recentlyClosedInvoiceListener()
        }
    }, [payeeId])
    //filter the invoice list to only those that are for this payee and are opened
    const openAndRecentlyClosedInvoiceList = Object.values(invoices.invoicesById)
                              .filter(
                                  i => i.payeeId === payeeId &&
                                  (
                                      i.closedAt === null ||
                                      (Date.now() - i.closedAt) <= TEMPORARY_NON_LIVE_VISIBILITY
                                  )
                                )
    //refresh the screen every minute to filter out newly closed invoices
    useScreenRefresh()
    return (
            <ListByDate 
                title={title}
                itemList={openAndRecentlyClosedInvoiceList}
                renderItem={
                            invoice => <InvoiceActivityListItem 
                                        invoice={invoice} 
                                        payeeHandleId={payeeHandleId}
                                        payeeName={payeeName}
                                    />
                            }
                placeholder={<div className="flex-center padding-1em margin-top-05em background-color-light-grey border-radius-8px color-secondary"><div className="icon-left display-flex align-items-center"><Icon icon="receipt"/> No Pending Invoices</div></div>}
            />
    )
}

const mapStateToProps = state => ({
    invoices: state.invoices,
})
const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(PendingInvoicesDateList)