import {COUNTRY_CODES} from "../constants/country"

export const isValidEmail = email => email.match(/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,15}$/g)
export const isValidLegalName = name => name.replace(/^\s+/, "").replace(/\s+$/, "").replace(/\s+/, " ")
export const isValidZipCode = zipCode => {
    if (typeof zipCode !== "string") return false
    return zipCode.match(/^[a-zA-Z0-9]{1,10}$/g)
}

export const validateAddress = (address={}) => {
    const errors = {}
    if (!address.line1) errors.line1 = "Please provide a street address"
    if (!address.city) errors.city = "Please provide a city"
    const country = COUNTRY_CODES[address.countryCode] 
    if (
        !country ||
        !address.countryCode || 
        !address.countryName
    ) errors.countryCode = "Please select a country"
    if (
        country && 
        !country.noZipCode
    ) {
        if (!address.zipCode) errors.zipCode = "Please provide a zip / postal code"
        else if (!isValidZipCode(address.zipCode)) errors.zipCode = "Zip / postal code is invalid"
    }
    return errors
}

export const validateProductVariant = (productVariant={}, variantsById={}, transactionLimitXcd=0) => {
    const errors = {}
    const {id, price, images, hasLimitedStock, quantityInStock, variantDetails} = productVariant
    //image
    if (!images || !Array.isArray(images) || !images[0] || typeof images[0] !== "object" || !images[0].med) errors.images = "Image required" 
    //check price
    if (isNaN(price)) errors.price = 'Price must be a number'
    if (!errors.price && price <= 0) errors.price = 'Price must be more than zero'
    if (!errors.price && !isValidCurrencyValue(price)) errors.price = 'Invalid currency total'
    if (!errors.price && Number(price) > Number(transactionLimitXcd)) errors.price = `Over your transaction limit $${transactionLimitXcd}. Contact Shopdm Pay Team to increase`
    //check variant details
    if (!variantDetails || typeof variantDetails !== 'string') errors.variantDetails = "Details are required"
    if (!errors.variantDetails && String(variantDetails).length > 30) errors.variantDetails = "30 chars max"
    //ensure another variant doesnt have the same details
    if (!errors.variantDetails && 
        Object.values(variantsById).find(v => v.id !== id && 
                                            String(v.variantDetails).trim().toLowerCase() === String(variantDetails).trim().toLowerCase())) errors.variantDetails = "Duplicate details"
    //ensure the stock values make sense
    if (typeof hasLimitedStock !== "boolean") errors.hasLimitedStock = "Must be true or false"
    if (hasLimitedStock) {
        if (isNaN(quantityInStock) || quantityInStock < 0) errors.quantityInStock = "Qty must be more than zero"
        if (!errors.quantityInStock && !isValidWholeNumber(quantityInStock)) errors.quantityInStock = "Qty must be a whole number"
    } else {
        if (quantityInStock > 0) errors.hasLimitedStock = "Qty in stock should not be limited"
     }
     if (isNaN(quantityInStock)) errors.quantityInStock = "Qty must be a number"
    return errors
}
export const isValidWholeNumber = number => {
    const numString = String(number)
    return numString.match(/^-?\d+$/g)
}
export const isValidWholeNumberInput = number => {
    if (number === "" || number === "-") return true
    return isValidWholeNumber(number)
}

export const isValidQuantity = number => isValidWholeNumberInput(number) && (number === "" || number >= 0)

export const isValidCurrencyValue = number => {
    return String(number).match(/^(0|([1-9][0-9]*))?(\.|\.[0-9]?[0-9])?$/g)
}
export const handleChangeCurrencyValue = (e, min=null, max=null, ) => {
    let value = e.target.value
    if (value === "") value = value
    else {
        const minSatisfied = typeof min !== 'number' || value >= min
        const maxSatisfied = typeof max !== 'number' || value <= max
        if(
            minSatisfied && 
            maxSatisfied &&
            value.match(/^(0|([0-9]*))?(\.|\.[0-9]?[0-9])?$/g)
        ) return value
        else return undefined
    }
    
    return value
}

const isValidAlphaNumericAndPunctuationRegex = /^(?!.*--)[a-zA-Z0-9., \-]+$/;
export const isValidAlphaNumericAndPunctuationValue = value => {
    value = String(value)
    return isValidAlphaNumericAndPunctuationRegex.test(value);
}

export const handleChangeAlphaNumericAndPunctuationValue = (e) => {
    let value = e.target.value
    if (value === "") value = value
    else {
        if (!isValidAlphaNumericAndPunctuationValue(value)) return undefined
    }
    return value
}

export const sanitizeInput = (input="", options={}) => {
    const DEFAULT_OPTIONS = {
        regex: /[^a-zA-Z0-9., \-]+|--+/g,
        maxCharLength: 255
    }
    const {regex, maxCharLength} = {...DEFAULT_OPTIONS, ...options}
    input = String(input).substring(0, maxCharLength)
    return input.replace(regex, ''); // Replace disallowed characters with an empty string
}

export const handleChangeZipCode = value => {
    if (typeof value !== "string") return ""
    if (value === "") return ""
    if(!isValidZipCode(value)) return undefined
    return value
}

