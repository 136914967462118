import React, {useState, useEffect, Suspense, lazy} from "react";

import ErrorBoundary from "../../components/ErrorBoundary";
import Loading from "../../components/Loading";
import { retryLazy } from "../../utils/applicationUtils";

import {connect} from "react-redux"
import {bindActionCreators} from "redux";
import * as actions from "../../actions"

import {DAY_IN_MILLISECONDS} from "../../constants/datetime"
import { monthlyViewsToDailyViewsFromDate } from "../../utils/chartUtils";
// Wrap your lazy loading with retry logic
const BarChart = lazy(() =>
  retryLazy(() => import("../../components/BarChart"))
);

  const PayeeCatalogChart = ({
    payeeCatalogId="", 
    payeeId,
    fromDate=Date.now() - (7 * DAY_IN_MILLISECONDS) ,
    toDate=Date.now(), 
    payeeCatalogs,
    actions
  }) => {
  
    //listen to views of the payeeCatalog made in the specified date range
    const [catalogViewsListener, setCatalogViewsListener] = useState(() => () => {})
    const listenToCatalogViews = async () => {
        const newCatalogViewsListener = await actions.fetchSubscribeToPayeeCatalogViewsFromDate(
          payeeCatalogId, 
          payeeId,
          fromDate
        )
        setCatalogViewsListener(() => newCatalogViewsListener)
    }
    useEffect(() => {
      listenToCatalogViews()
        return () => {
            if (typeof catalogViewsListener === "function") catalogViewsListener()
        }
    }, [payeeCatalogId])

    if (!payeeCatalogId) return ""
    const dailyViewArray = monthlyViewsToDailyViewsFromDate(
                            payeeCatalogs.monthlyViewsByPayeeCatalogId[payeeCatalogId],
                            fromDate
                          )
                          .filter(views => views.dayStartAt >= fromDate && views.dayStartAt <= toDate)
    return (
      <div className="padding-05em background-color-light-grey border-radius-8px">
        <ErrorBoundary
            errorHeading="Sorry, we could not load the bar chart. Check your internet connection and retry"
            errorContext="PayeeCatalogChart Lazy-Loading BarChart"
        >
          <Suspense fallback={<Loading />}>
            <BarChart 
                dataArray={dailyViewArray} 
                dataKey="views" 
                labelKey="date"
                height={"35vh"}
            />
          </Suspense>
        </ErrorBoundary>
      </div>
    )
  }

  const mapStateToProps = state => ({
    payeeCatalogs: state.payeeCatalogs,
})

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(PayeeCatalogChart)