import React, {useEffect} from "react"
import {Outlet} from "react-router-dom"

import {connect} from "react-redux"
import {bindActionCreators} from "redux";
import * as actions from "../../actions"

import {useParams} from "react-router-dom"

function PayeeStoreLayout ({payees, payeeCatalogs, actions}) {
    //TODO instead of duplicating this mapping of handles to doc ids,
    //we can wrap the outlet below in a custom provider that loads these
    //that would give everyone access to it. Not attempting now
    const { catalogHandleId, payeeHandleId } = useParams();
    const payeeId = payees.payeeIdByHandleId[payeeHandleId]
    const catalogId = payeeId && catalogHandleId && payeeCatalogs.payeeCatalogIdByPayeeCatalogHandleId[payeeId] ? 
                        payeeCatalogs.payeeCatalogIdByPayeeCatalogHandleId[payeeId][catalogHandleId]
                         :
                         ""
    useEffect(() => {
        if (catalogId) actions.callViewPayeeCatalog(catalogId)
    }, [catalogId])
    return (
        <div>
            <div className="main">
                <Outlet />
            </div>
        </div>
    )
}

const mapStateToProps = state => ({
    payeeCatalogs: state.payeeCatalogs,
    payees: state.payees
})
const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(PayeeStoreLayout)