import React from "react"
import {Outlet, Link} from "react-router-dom"
import Navigation from "../../components/Navigation"
import Icon from "../../components/Icon"
import styles from "./PayLayout.module.css"
import {useSearchParams} from "react-router-dom"

function PayLayout ({}) {

    //if search params are provided, it is a One Time Payment web integration, 
    //in that case, we do not want the user to be able to navigate back to home
    const [searchParams] = useSearchParams()
    const hideNavigation = Boolean(searchParams.has("total_xcd") || searchParams.has("reason") || searchParams.has("signature") || searchParams.has("invoice_id"))
    return (
        <div className={styles.container}>
            <Navigation backgroundColor="var(--white)" navigate={!hideNavigation}>
                {
                    !hideNavigation ?
                    <ul className="navMain">
                        <li>
                            <button className="button secondary"><Link to="/" className=" icon icon-left flex-center"><Icon icon="arrow-back"/> Back To Home</Link></button>
                        </li>
                    </ul>
                    :
                    null
                }
            </Navigation>
            <div className="main padding-1em">
                <Outlet />
            </div>
        </div>
    )
}

  export default PayLayout