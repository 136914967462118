export const TEMPORARY_NON_LIVE_VISIBILITY = 60 * 1000 

export const AVATAR_SHAPE_ROUND="round"
export const AVATAR_SHAPE_SQUARE="square"

export const AVATAR_SIZE_SMALL="small"
export const AVATAR_SIZE_MEDIUM="medium"
export const AVATAR_SIZE_BIG="big"


export const VIEW_TYPE_LIST = "VIEW_TYPE_LIST"
export const VIEW_TYPE_GRID = "VIEW_TYPE_GRID"
export const DISPLAY_TYPE_TILE = "DISPLAY_TYPE_TILE"
export const DISPLAY_TYPE_ROW = "DISPLAY_TYPE_ROW"