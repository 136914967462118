import React from "react"

import PayeeProductForm from "../PayeeProductForm"

import {connect} from "react-redux"
import {bindActionCreators} from "redux";
import * as actions from "../../actions"

const UpdatePayeeProductForm = ({
    payeeProductId,
    payeeId,
    transactionLimitXcd,
    title="Update Product",
    buttonText="Update Product",
    defaultValues={},
    postSubmit=()=>{}, 
    onCancel=()=>{}, 
    onSuccess=()=>{},
    actions,
    payeeProducts
}) => {
    const payeeProduct = payeeProducts.payeeProductsById[payeeProductId]
    if (!payeeProduct) return
    const handleSubmit = async (
        name,
        handleId, //cannot be edited
        productType,
        price,
        description,
        defaultVariantId,
        hasVariants,
        variantsById,
        isInactive,
        handleSubmitSuccess,
        handleSubmitError
    ) => {
        return await actions.fetchUpdatePayeeProduct(
                payeeProductId,
                {
                    name,
                    productType,
                    price,
                    description,
                    defaultVariantId,
                    hasVariants,
                    variantsById,
                    isInactive,
                },
                handleSubmitSuccess,
                handleSubmitError
        )

    }
    return (
        <PayeeProductForm 
            title={title}
            defaultValues={{
                ...payeeProduct,
                ...defaultValues
            }}
            buttonText={buttonText}
            onSubmit={handleSubmit}
            postSubmit={postSubmit}
            onCancel={onCancel}
            onSuccess={onSuccess}
            actions={actions}
            payeeId={payeeId}
            payeeProductId={payeeProductId}
            transactionLimitXcd={transactionLimitXcd}
        />
    )
}

const mapStateToProps = state => ({
    payeeProducts: state.payeeProducts
})

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch)
});
  
export default connect(mapStateToProps, mapDispatchToProps)(UpdatePayeeProductForm)