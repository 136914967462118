import {firebaseApp, baseUrl} from "../config/firebase"
import {getFirestore, collection, query, getDoc, doc, setDoc, where, onSnapshot, updateDoc} from "firebase/firestore";
import {firebaseFetch} from "../utils/firebase"
import {logError} from "../utils/errorHandlingUtils"
import {DAY_IN_MILLISECONDS} from "../constants/datetime"
import {INVOICE_STATUS_CANCELLED} from "../constants/invoices";

export const SAVE_INVOICES = 'SAVE_INVOICES'
export const CREATE_INVOICE = 'CREATE_INVOICE'

export const saveInvoices = invoices => {
    return {
        type: SAVE_INVOICES,
        payload: {
            invoices
        }
    }
}

export const createInvoice = invoice => {
    return {
        type: CREATE_INVOICE,
        payload: {
            invoice
        }
    }
}

export const fetchCreateInvoice = (
    id,
    payeeId,
    totalXcd,
    reason,
    invoiceType,
    currentStatus,
    billToCustomer,
    onSuccess = () => {
    },
    onError = () => {
    }
) => {
    const firestore = getFirestore(firebaseApp);
    const invoicesRef = doc(firestore, "invoices", id)
    const invoice = {
        id,
        payeeId,
        totalXcd: Number(totalXcd),
        reason,
        invoiceType,
        currentStatus,
        statusHistory: {
            [currentStatus]: Date.now()
        },
        billToCustomer,
        payeeCustomerId: billToCustomer.id,
        createdAt: Date.now(),
        closedAt: null,
    }
    return async (dispatch, getState) => {
        try {
            const {user, device} = getState()
            invoice.createdByDeviceId = device.id ? device.id : null
            invoice.createdByUserId = user.id ? user.id : null
            await setDoc(invoicesRef, invoice)
            dispatch(createInvoice(invoice))
            onSuccess(invoice)
            return true
        } catch (e) {
            const message = `action > invoices > fetchCreateInvoice: Failed to create invoice ${JSON.stringify(invoice)}`
            if (e.message_) {
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            onError(invoice)
            return false
        }

    }
}

export const fetchCancelInvoice = (
    invoiceId,
) => {
    /**
     * Purpose: update invoice status to cancelled
     */
    if (!invoiceId) {
        return false
    }

    const firestore = getFirestore(firebaseApp)
    const invoicesRef = doc(firestore, "invoices", invoiceId)

    return async () => {
        try {
            const invoiceDocRef = await getDoc(invoicesRef)
            if (!invoiceDocRef.exists()) {
                throw new Error(`Invoice does not exist`)
            }

            await updateDoc(invoicesRef, {
                currentStatus: INVOICE_STATUS_CANCELLED,
                statusHistory: {
                    [INVOICE_STATUS_CANCELLED]: Date.now()
                },
                statusMessage: "Invoice cancelled by payee",
            });

            return true
        } catch (e) {
            const message = `action > invoices > fetchCancelInvoice: Failed to cancel invoice ${invoiceId}`
            logError(`${e.message_ ? e.message_ : e.message} ${message}`)
            return false
        }
    }
}

export const httpFetchSaveInvoice = (
    invoiceId = "",
    onSuccess = () => {
    },
    onError = () => {
    }
) => {
    /**
      * Purpose: save one invoice from the firestore database
      */
    console.time(`httpFetchSaveInvoice ${invoiceId}`)
    if (!invoiceId) return false
    return async dispatch => {
        try {

            const invoice = await firebaseFetch(
                "invoices", 
                invoiceId,
            )
            console.timeEnd(`httpFetchSaveInvoice ${invoiceId}`)
            if (invoice) {
                dispatch(saveInvoices([invoice]))
            } else throw new Error(`Invoice does not exist`)
            onSuccess(invoiceId)
            return true
        } catch (e) {
            const message = `action > invoices > httpFetchSaveToInvoice: Failed to save invoice ${invoiceId}`
            if (e.message_) {
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            onError(invoiceId)
            return false
        }
    }
}

export const fetchSubscribeToInvoice = (
    invoiceId = "",
    onLoad = () => {
    }
) => {
    /**
     * Purpose: retrieve one invoice from the firestore database
     * Note: the onSnapshot below watches for changes on the server
     */
    if (!invoiceId) return () => {
    }
    const firestore = getFirestore(firebaseApp)
    const invoicesRef = doc(firestore, "invoices", invoiceId)
    return async dispatch => {
        try {
            const invoicesListener = await onSnapshot(invoicesRef,
                async docRef => {
                    if (!docRef.exists()) {
                        onLoad()
                        return
                    }
                    const invoice = {...docRef.data()};
                    dispatch(saveInvoices([invoice]))
                    onLoad()
                })
            return invoicesListener
        } catch (e) {
            const message = `action > invoices > fetchSubscribeToInvoice: Failed to save invoice ${invoiceId}`
            if (e.message_) {
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            return () => {
            }
        }
    }
}

export const fetchSubscribeToOpenPayeeInvoices = payeeId => {
    /**
     * Purpose: retrieve the payee's open invoices from the firestore database
     * Note: the onSnapshot below watches for changes to the center on the server
     */
    const firestore = getFirestore(firebaseApp)
    const invoicesRef = query(collection(firestore, "invoices"),
        where("closedAt", "==", null),
        where("payeeId", "==", payeeId))

    return async dispatch => {
        try {
            const invoicesListener = await onSnapshot(invoicesRef,
                querySnapshot => {
                    //get an array of invoices from the snapshot
                    const invoices = querySnapshot.docs.map(docRef => ({...docRef.data()}));
                    dispatch(saveInvoices(invoices))
                }
            )
            return invoicesListener
        } catch (e) {
            const message = `action > invoices > fetchSubscribeToOpenPayeeInvoices: Failed to save open invoices for payee ${payeeId}`
            if (e.message_) {
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            return () => {
            }
        }
    }
}

export const fetchSubscribeToRecentlyClosedPayeeInvoices = (
    payeeId,
    fromDate = Date.now() - DAY_IN_MILLISECONDS//defaults to listening to all  closed in the last day
) => {
    /**
     * Purpose: retrieve the recently closed invoices for one payee from the firestore database
     * Note: the onSnapshot below watches for changes to the center on the server
     */
    const firestore = getFirestore(firebaseApp)
    const invoicesRef = query(collection(firestore, "invoices"),
        where("closedAt", ">=", fromDate),
        where("payeeId", "==", payeeId))


    return async dispatch => {
        try {
            const invoicesListener = await onSnapshot(invoicesRef,
                querySnapshot => {
                    //get an array of invoices from the snapshot
                    const invoices = querySnapshot.docs.map(docRef => ({...docRef.data()}));
                    dispatch(saveInvoices(invoices))
                }
            )
            return invoicesListener
        } catch (e) {
            const message = `action > invoices > fetchSubscribeToRecentlyClosedPayeeInvoices: Failed to save recently closed invoices for payee ${payeeId}`
            if (e.message_) {
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            return () => {
            }
        }
    }
}

export const fetchSubscribeToPayeeInvoicesInDateRange = (
    payeeId,
    fromDate = Date.now() - (DAY_IN_MILLISECONDS * 30),//defaults to listening to all invoices created in the last 30 days
    toDate = Date.now() + (DAY_IN_MILLISECONDS * 30) //go a month into the future
) => {
    const firestore = getFirestore(firebaseApp)
    const invoicesRef = query(
        collection(firestore, "invoices"),
        where("payeeId", "==", payeeId),
        where("createdAt", ">=", fromDate),
        where("createdAt", "<=", toDate)
    )
    return async (dispatch) => {
        try {
            const invoicesListener = await onSnapshot(invoicesRef,
                querySnapshot => {
                    //get an array of invoices from the snapshot
                    const invoices = querySnapshot.docs.map(docRef => ({...docRef.data()}));
                    dispatch(saveInvoices(invoices))
                }
            )
            return invoicesListener
        } catch (e) {
            const message = `action > invoices > fetchSubscribeToPayeeInvoicesInDateRange: Failed to save invoices for ${payeeId}`
            if (e.message_) {
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            return () => {
            }
        }
    }
}

export const fetchViewInvoice = (
    invoiceId,
    onSuccess = () => {
    },
    onError = () => {
    }
) => {
    return async (dispatch, getState) => {
        try {
            const {device, user} = getState()
            const link = `${baseUrl}v1/invoices/view`
            const response = await fetch(link, {
                "method": 'POST',
                "headers": {
                    "Content-Type": "application/json",
                    "Accept": "application/json"
                },
                "body": JSON.stringify({
                    invoiceId,
                    deviceId: device.id,
                    userId: user && user.id ? user.id : ""
                })
            })
            if (!response.ok) {
                throw new Error("something went wrong")
            }
            onSuccess(invoiceId)
            return true
        } catch (e) {
            e.message = `${e.message} action > invoices > fetchViewInvoice: Failed to view invoice ${invoiceId}`
            logError(e)
            onError(e)
            return false
        }
    }
}