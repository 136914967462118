export const DEFAULT_PAYER_VALUES_US = {
    phoneNumber: "+1",
    name: "",
    address: {
        city: "",
        line1: "",
        zipCode: "",
        countryName: "United States of America",
        countryCode: "US",
    },
    email: "",
}

export const DEFAULT_PAYER_VALUES_DM = {
    phoneNumber: "+1767",
    name: "",
    address: {
        city: "Roseau",
        line1: "",
        zipCode: "",
        countryName: "Dominica",
        countryCode: "DM",
    },
    email: "",
}