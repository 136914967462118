import {firebaseApp, baseUrl} from "../config/firebase"
import {getFirestore, collection, query, getDoc, doc, setDoc, where, onSnapshot, updateDoc, serverTimestamp} from "firebase/firestore";

import { MESSAGE_STATUS_PENDING } from "../constants/messages";
import {logError} from "../utils/errorHandlingUtils"

export const SAVE_MESSAGES = 'SAVE_MESSAGES'
export const CREATE_MESSAGE = 'CREATE_MESSAGE'

export const saveMessages = messages => {
    return {
        type: SAVE_MESSAGES,
        payload: {
            messages
        }
    }
}

export const createMessage = message => {
    return {
        type: CREATE_MESSAGE,
        payload: {
            message
        }
    }
}

export const fetchCreateMessage = (
    id,
    messageType,
    templateType,
    to={},
    params={},
    description,
    payeeId,
    onSuccess = () => {
    },
    onError = () => {
    }
) => {
    const firestore = getFirestore(firebaseApp);
    const messagesRef = doc(firestore, "messages", id)
    const message = {
        id,
        messageType,
        templateType,
        to,
        params,
        description,
        status: MESSAGE_STATUS_PENDING,
        createdAt: Date.now(),
        createdAtTimestamp: serverTimestamp(),
        createdByPayeeId: payeeId,
    }
    return async (dispatch, getState) => {
        try {
            const {user, device} = getState()
            message.createdByDeviceId = device.id ? device.id : null
            message.createdByUserId = user.id ? user.id : null
            await setDoc(messagesRef, message)
            dispatch(createMessage(message))
            onSuccess(message)
            return true
        } catch (e) {
            const errMessage = `action > messages > fetchCreateMessage: Failed to create message ${JSON.stringify(message)}`
            if (e.message_) {
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${errMessage}`))
            } else {
                e.message = `${e.message} ${errMessage}`
                logError(e)
            }
            onError(errMessage)
            return false
        }

    }
}