import React, { useState } from "react"
import Modal from "../components/Modal"
import QRShareLink from "../containers/QRShareLink"

/**
 * Purpose:
 * 
 * - easily add QR share functionality to a component, 
 * - all of the params necessary to create the link must be passed to the wrapped component
 * - the component can then make use of setQRScanLink to set the link that will be encoded into the QR code
 * 
 * @param {payeeHandleId} Component 
 * @returns 
 */
const withQRScanModal = (Component) => {
    return function WithQRScanModal (props) {
        const [qrScanLink, setQRScanLink] = useState("")
        const [imageUrl, setImageUrl] = useState("")
        const [paymentRequestMessage, setPaymentRequestMessage] = useState("")
        return (
            <React.Fragment>
                <Component 
                    {...props}
                    setQRScanLink={setQRScanLink}
                    setImageUrl={setImageUrl}
                    setPaymentRequestMessage={setPaymentRequestMessage}
                />
                <Modal
                    isOpen={Boolean(qrScanLink)}
                    closeModal={() => setQRScanLink("")}
                    showCloseButton={false}
                    className="height-fit-content"
                    overlayClassName="flex-center"
                    closeOnOverlayClick={true}
                >
                {
                    qrScanLink ?
                    <QRShareLink 
                        link={qrScanLink} 
                        paymentRequestMessage={
                            paymentRequestMessage ? paymentRequestMessage : //if set in state it overrides props
                            props.paymentRequestMessage
                        } 
                        imageUrl={imageUrl}
                    />
                    :
                    null
                }
                </Modal>
            </React.Fragment>
        )

    }
}

export default withQRScanModal