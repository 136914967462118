export const ADD_TO_CART = "ADD_TO_CART"
export const REMOVE_FROM_CART = "REMOVE_FROM_CART"
export const CLEAR_CART = 'CLEAR_CART'

export const addToCart = (item={}, quantity=0, cartId="", payeeCatalogId="") => {
    return {
        type: ADD_TO_CART,
        payload: {
            item: {
                ...item,
                quantity
            },
            cartId,
            payeeCatalogId
        }
    }
}

export const removeFromCart = (variantId="", cartId="") => {
    return {
        type: REMOVE_FROM_CART,
        payload: {
            itemId: variantId,
            cartId
        }
    }
}

export const clearCart = cartId => {
    return {
        type: CLEAR_CART,
        payload: {cartId}
    }
}