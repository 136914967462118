import {firebaseApp} from "../config/firebase"
import {getFunctions, httpsCallable} from "firebase/functions";
import {getFirestore, collection, query, limit,  where, onSnapshot} from "firebase/firestore";
import {firebaseFetch} from "../utils/firebase"
import {logError} from "../utils/errorHandlingUtils"
import {getTimestampForStartOfMonth} from "../utils/datetimeUtils"

export const SAVE_PAYEE_CATALOGS = 'SAVE_PAYEE_CATALOGS'
export const CREATE_PAYEE_CATALOG = 'CREATE_PAYEE_CATALOG'
export const UPDATE_PAYEE_CATALOG = 'UPDATE_PAYEE_CATALOG'
export const SAVE_PAYEE_CATALOG_VIEWS_BY_MONTH = 'SAVE_PAYEE_CATALOG_VIEWS_BY_MONTH'

export const savePayeeCatalogs = payeeCatalogs => {
    return {
        type: SAVE_PAYEE_CATALOGS,
        payload: {
            payeeCatalogs
        }
    }
}

export const savePayeeCatalogViewsByMonth = payeeCatalogViewsByMonth => {
    return {
        type: SAVE_PAYEE_CATALOG_VIEWS_BY_MONTH,
        payload: {
            payeeCatalogViewsByMonth
        }
    }
}

export const httpFetchSavePayeeCatalogByHandleId = (
    payeeCatalogHandleId = "",
    payeeId = "",
    onLoad = () => {
    }
) => {
    /**
      * Purpose: retrieve one payee catalog via its handleId from the firestore database
      */
    console.time(`httpFetchSavePayeeCatalogByHandleId ${payeeCatalogHandleId}`)
    if (!payeeCatalogHandleId || !payeeId) return false
    return async dispatch => {
        try {
            const payeeCatalogs = await firebaseFetch(
                "payeeCatalogs", 
                [
                    ["handleId", "==", payeeCatalogHandleId],
                    ["payeeId", "==", payeeId],
                    ["deleted", "==", false]
                ],
                {limit: 1}
            )
            dispatch(savePayeeCatalogs(payeeCatalogs))
            onLoad()
            console.timeEnd(`httpFetchSavePayeeCatalogByHandleId ${payeeCatalogHandleId}`)
            return true
        } catch (e){
            const message = `action > payees > httpFetchSavePayeeCatalogByHandleId: Failed to save payee with handle ${payeeCatalogHandleId}`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            return false
        }
    }
}

export const fetchSubscribeToPayeeCatalogByHandleId = (
    payeeCatalogHandleId = "",
    payeeId = "",
    onLoad = () => {
    }
) => {
    /**
     * Purpose: retrieve one payee catalog via its handleId from the firestore database
     * Note: the onSnapshot below watches for changes on the server
     */
    if (!payeeCatalogHandleId || !payeeId) return () => {
    }
    const firestore = getFirestore(firebaseApp)
    const payeeCatalogsRef = query(collection(firestore, "payeeCatalogs"), 
                                where("handleId", "==", payeeCatalogHandleId),
                                where("payeeId", "==", payeeId),
                                where("deleted", "==", false),
                                limit(1)
                                )
    return async dispatch => {
        try {
            const payeeCatalogsListener = await onSnapshot(payeeCatalogsRef, 
                querySnapshot => {
                    //get an array of payee catalogs from the snapshot
                    const payeeCatalogs = querySnapshot.docs.map(docRef => ({...docRef.data()}));
                    dispatch(savePayeeCatalogs(payeeCatalogs))
                    onLoad()
                } )
            return payeeCatalogsListener
        } catch (e) {
            const message = `action > payeeCatalogs > fetchSubscribeToPayeeCatalogByHandleId: Failed to save payee catalog with handle ${payeeCatalogHandleId} from payee ${payeeId}`
            if (e.message_) {
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            return () => {
            }
        }
    }
}

export const fetchSubscribeToPayeeCatalogs = (
    payeeId,
) => {
    const firestore = getFirestore(firebaseApp)
    const payeeCatalogsRef = query(
        collection(firestore, "payeeCatalogs"),
        where("payeeId", "==", payeeId),
    )
    return async (dispatch) => {
        try {
            const payeeCatalogsListener = await onSnapshot(payeeCatalogsRef,
                querySnapshot => {
                    //get an array of payee catalogs from the snapshot
                    const payeeCatalogs = querySnapshot.docs.map(docRef => ({...docRef.data()}));
                    dispatch(savePayeeCatalogs(payeeCatalogs))
                }
            )
            return payeeCatalogsListener
        } catch (e) {
            const message = `action > payeeCatalogs > fetchSubscribeToPayeeCatalogs: Failed to subscribe to catalogs for ${payeeId}`
            if (e.message_) {
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            return () => {
            }
        }
    }
}

export const callViewPayeeCatalog = (
    payeeCatalogId,
    onSuccess = () => {
    },
    onError = () => {
    }
) => {
    const functions = getFunctions();
    return async (dispatch, getState) => {
        try {
            const {device, user} = getState()
            //do not track views for admins
            if (user.isAdmin) return false
            const viewPayeeCatalogResponse = await httpsCallable(functions, 'viewPayeeCatalog')({
                payeeCatalogId,
                deviceId: device.id || "",
                userId: user && user.id ? user.id : ""
            });
            console.log("view response", viewPayeeCatalogResponse)
            if (!viewPayeeCatalogResponse.data.success) {
                return false
            }
            onSuccess(payeeCatalogId)
            return true
        } catch (e) {
            e.message = `${e.message} action > payeeCatalogs > callViewPayeeCatalog: Failed to view payee catalog ${payeeCatalogId}`
            logError(e)
            onError(e)
            return false
        }
    }
}

export const fetchSubscribeToPayeeCatalogViewsFromDate = (
    payeeCatalogId,
    payeeId,
    fromDate=Date.now()
) => {
    if (!payeeCatalogId) return () => {}
    //convert the from data to a start of month date, since the view docs are organized by month
    const startOfMonth = getTimestampForStartOfMonth(fromDate, false)
    const firestore = getFirestore(firebaseApp)
    const payeeCatalogViewsRef = query(
        collection(firestore, `payeeCatalogs/${payeeCatalogId}/views`),
        where("payeeId", "==", payeeId),
        where("monthStartAt", ">=", startOfMonth),
    )
    return async (dispatch) => {
        try {
            const payeeCatalogViewsListener = await onSnapshot(payeeCatalogViewsRef,
                querySnapshot => {
                    //get an array of payee catalogs from the snapshot
                    const payeeCatalogViewsByMonth = querySnapshot.docs.map(docRef => ({...docRef.data()}));
                    dispatch(savePayeeCatalogViewsByMonth(payeeCatalogViewsByMonth))
                }
            )
            return payeeCatalogViewsListener
        } catch (e) {
            const message = `action > payeeCatalogs > fetchSubscribeToPayeeCatalogViews: Failed to subscribe to views for payee catalog ${payeeCatalogId}`
            if (e.message_) {
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            return () => {
            }
        }
    }
}