import React from "react"

import RadioButton from "../../components/RadioButton";
import AmountSmallAfterDecimal from "../../components/AmountSmallAfterDecimal";
import DropdownMenu from "../../components/DropdownMenu";

import {connect} from "react-redux"
import {bindActionCreators} from "redux";
import * as actions from "../../actions"

import styles from "./PayeeProductVariant.module.css"

const PayeeProductVariant = ({
                          variantId,
                          payeeProduct={},
                          actions
}) => {
    if (!variantId) return ""
    if (!payeeProduct || typeof payeeProduct !== "object") return ""
    const variant = payeeProduct.variantsById ? 
                    payeeProduct.variantsById[variantId]
                    :
                    null
    if (!variant) return ""
    const {price, variantDetails, quantitySold=0, 
        hasLimitedStock=false, quantityInStock=0, 
        images=[{med: "", small: ""}]
    } = variant
    const imageUrl =  images[0] && images[0].med ? images[0].med : payeeProduct.imageUrl
    const imageUrlSmall = images[0] && images[0].small ? images[0].small : imageUrl
    //change the default variant to the selected variant
    const setDefaultVariant = async (id, isInactive) => {
        //if this variant is already the default variant, do nothing
        if (payeeProduct.defaultVariantId === variantId) return
        if (!window.confirm(`Are you sure you want to make ${variantDetails} the default ${payeeProduct.name}?`)) return
        actions.toggleLoading(true)
        await actions.fetchUpdatePayeeProduct(
            payeeProduct.id, 
            {
                defaultVariantId: variantId,
                price,
                imageUrl
            })
        actions.toggleLoading(false)
    }
    //behave as inactive if either the varaint or overall product is inactive
    const isInactive = variant.isInactive || payeeProduct.isInactive
    //toggle the product's active/inactive state
    const togglePayeeProductIsInactive = async (isInactive) => {
        if (payeeProduct.defaultVariantId === variantId) {
            alert(`Cannot deactivate ${variantDetails} because it is the default option. Set a different default or deactivate the whole product, if you want to make the product unavailable`)
            return
        }
        actions.toggleLoading(true)
        await actions.fetchUpdatePayeeProduct(payeeProduct.id, 
                {
                    variantsById: {
                        ...payeeProduct.variantsById,
                        [variantId]: {
                            ...variant,
                            isInactive
                        }
                    }
                })
        actions.toggleLoading(false)
    }
    //allow setting the product active/inactive
    const menuItems = []
    let variantName = variantDetails
    if (variantName.length > 15) variantName = `${variantName.slice(0, 15)}...`
    if (variant.isInactive) menuItems.push({
        name: `Activate ${variantName}`,
        label: `Activate ${variantName}`,
        onClick: () => togglePayeeProductIsInactive(!isInactive)
    })
    else menuItems.push({
        name: `Deactivate ${variantName}`,
        label: `Deactivate ${variantName}`,
        onClick: () => togglePayeeProductIsInactive(!isInactive)
    })
    return (
        <div className={`card font-size-12px text-align-left ${isInactive ? styles.isInactive : ""}`}>
            <div className="display-flex justify-content-space-between align-items-">
                <div className="display-flex align-items-center text-align-center">
                    <div className="display-flex">
                        {
                            !isInactive ? //do not show if inactive
                            <RadioButton 
                                onChange={setDefaultVariant}
                                checked={payeeProduct.defaultVariantId === variantId}
                            />
                            :
                            null
                        }
                        <img src={imageUrlSmall} height={30} width="auto"/>
                    </div>
                    <div className="margin-left-05em">{variantDetails}</div>
                    <div className="margin-left-05em font-weight-600">EC$<AmountSmallAfterDecimal amount={price}/></div>
                </div>
                <div className="margin-left-05em">
                    {
                        !payeeProduct.isInactive ?
                        <DropdownMenu 
                            menuItems={menuItems}
                        />
                        :
                        null
                    }
                </div>
            </div>
            <div className={`${styles.bottom} display-flex justify-content-space-between align-items-center`}>
                <div className="display-flex font-size-10px">
                    {
                        quantitySold > 0?
                        <div>{quantitySold} sold</div>
                        :
                        null
                    }
                    {/* separator is only need if both qty sold and qty in stock is displayed*/}
                    {
                        quantitySold > 0 && hasLimitedStock ?
                        <div className="margin-right-05em margin-left-05em">|</div>
                        :
                        null
                    }
                    {
                        hasLimitedStock ?
                        <div>{quantityInStock} in stock</div>
                        :
                        null
                    }
                </div>
                <div className="margin-left-05em">
                    {
                        payeeProduct.defaultVariantId === variantId
                        ?
                        <div className="badge small success">default</div>
                        :
                        ""
                    }
                </div>
                
            </div>
        </div>
    )
}

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch)
});

export default connect(null, mapDispatchToProps)(PayeeProductVariant)