import React from "react"
import styles from "./IncrementInput.module.css"

import debounce from 'lodash.debounce'
//we use the constants below to identify whether the change is coming from the input field or the buttons
const CHANGE_SOURCE_BUTTON = 'CHANGE_SOURCE_BUTTON' 
const CHANGE_SOURCE_INPUT = 'CHANGE_SOURCE_INPUT' 

class IncrementInput extends React.PureComponent {

    constructor(props){
        super(props)
        const value = props.value ? props.value : 0
        this.state = {
            value,
            error: null
        }
    }

    componentDidUpdate = (prevProps) => {
        //if the value changes, update it in state
        if (
            prevProps.value !== this.props.value
        ){
            this.setState({value: this.props.value})
        } 
        //if the max changes 
        //and it is now less than the value, 
        //then set the value to the max
        else if (
            prevProps.max !== this.props.max &&
            this.props.max < this.props.value
        ){
            this.handleChangeValue(this.props.max)
        }
    }
    static defaultProps = {
        onChange: () => {},
        onBelowMin: () => {},
        onAboveMax: () => {},
        min: 0,
        max: undefined,
        editable: true,
        onKeyDown: () => {},
        onBlur: null,
        buttonStyle: {}
    }
    
    handleChange = (e, changeSource) => {
        const string = e.target.value
        if (typeof string !== "string") return // we only process strings!  
        //if a blank string is entered, allow and display it
        //but do not update the value in props
        //this allows users to write in the required value
        else if (string === "") this.setState({value: string})
        else if (
            !isNaN(string) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
            !isNaN(parseFloat(string)) // ...and ensure strings of whitespace f
        ){
            const value = Number(string)
            this.handleChangeValue(value, changeSource)
        }
    }

    debouncedOnBlur =  debounce(value => this.handleBlur(value), 1000)

    handleChangeValue = (value, changeSource) => {
        value = Number(value)
        const {onChange, min, max, onBlur, onBelowMin, onAboveMax} = this.props
        const minSatisfied = typeof min !== 'number' || value >= min
        const maxSatisfied = typeof max !== 'number' || value <= max
        if(minSatisfied && maxSatisfied) {
            onChange(value)
            //if the change is coming from the buttons
            //then call on blur after a second
            if (changeSource === CHANGE_SOURCE_BUTTON && typeof onBlur === "function") this.debouncedOnBlur(value)
        } else {
            //if specific actions should be taken when min and max are unsatified
            if (!minSatisfied) onBelowMin()
            if (!maxSatisfied) onAboveMax()
            this.highlightError()
        }
    }

    handleBlur = () => {
        const {onBlur} = this.props
        //if focus is lost while a blank string is displayed in state
        //then replace state's value with the numerical value stored in props
        if (this.state.value === ""){
            this.setState({value: this.props.value})
        } else if (typeof onBlur === "function") onBlur(this.state.value)
    }

    highlightError = () => {
        this.setState({error: true})
        setTimeout(() => this.setState({error: false}), 1000)
    }
    
    componentWillUnmount () {
        this.debouncedOnBlur.cancel()
    }
    render() {
        const {
            min,
            max,
            editable,
            onKeyDown,
            buttonStyle={}
        } = this.props
        const {value, error} = this.state
        return (
            <div className={`${styles.container} ${error ? styles.error : ""}`}>
                <button 
                    className={styles.button}
                    onClick={()=> this.handleChangeValue(value - 1, CHANGE_SOURCE_BUTTON)}
                    style={buttonStyle}
                >-</button>
                <input 
                    className={styles.input}
                    readOnly={!editable}
                    value={value}
                    inputMode="numeric"
    
                    onChange={editable ? e => this.handleChange(e, CHANGE_SOURCE_INPUT): ()=>{}}
                    onKeyDown={onKeyDown}
                    min={0}
                    max={max}
                    onFocus={e => e.target.select()}
                    onBlur={this.handleBlur}
                />
                <button 
                    className={styles.button}
                    onClick={()=> this.handleChangeValue(value + 1, CHANGE_SOURCE_BUTTON)}
                    style={buttonStyle}
                >+</button>
            </div>
        )
    }
}

export default IncrementInput