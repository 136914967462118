import {firebaseApp} from "../config/firebase"
import { getFirestore, doc, where, limit, query, collection, onSnapshot, getDocs, getDoc} from "firebase/firestore";
import { logError } from "../utils/errorHandlingUtils"
import { getFirestoreObjectsByIdList, firebaseFetch} from "../utils/firebase"
import { PAYEE_TYPE_PAYDM_MERCHANT, PAYEE_TRANSACTION_FEE_SETTINGS_ID } from "../constants/payees";

export const SAVE_PAYEES = 'SAVE_PAYEES'
export const SAVE_PAYEE_FEE_SETTINGS = 'SAVE_PAYEE_FEE_SETTINGS'

export const savePayees = payees => {
    return {
        type: SAVE_PAYEES,
        payload: {
            payees
        }
    }
}

export const savePayeeFeeSettings = (
    feeSettings={},
    payeeId=""
) => {
    return {
        type: SAVE_PAYEE_FEE_SETTINGS,
        payload: {
            feeSettings,
            payeeId
        }
    }
}

export const fetchSubscribeToPayee = (payeeId, onLoad=()=>{}) => {
    /**
      * Purpose: retrieve one payee from the firestore database
      * Note: the onSnapshot below watches for changes to the payee on the server
      */
    const firestore = getFirestore(firebaseApp)
    const payeeRef = doc(firestore, "payees", payeeId)
                                
    return async (dispatch) => {
        try {
            const payeeListener = await onSnapshot(payeeRef,
                docRef => {
                    if (!docRef.exists()) {
                        onLoad()
                        return
                    }
                    //get one payee from the snapshot
                    const payee = {...docRef.data()}
                    dispatch(savePayees([payee]))
                    onLoad()
                } 
            )
            return payeeListener
        } catch (e){
            const message = `action > payees > fetchSubscribeToPayee: Failed to subscribe to payee`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            return () => {}
        }
    }
}

export const httpFetchSaveMerchantPayeeByHandleId = (handleId="", onSuccess=()=>{}, onError=()=>{}) => {
    /**
      * Purpose: retrieve one payee from the firestore database by its handle id
      */
    console.time(`httpFetchSaveMerchantPayeeByHandleId ${handleId}`)
    if (!handleId) return false
    return async dispatch => {
        try {
            const payees = await firebaseFetch(
                "payees", 
                [
                    ["handleId", "==", handleId],
                    ["payeeType", "==", PAYEE_TYPE_PAYDM_MERCHANT],
                ],
                {limit: 1}
            )
            dispatch(savePayees(payees))
            console.timeEnd(`httpFetchSaveMerchantPayeeByHandleId ${handleId}`)
            if (payees.length > 0) onSuccess()
            return true
        } catch (e){
            const message = `action > payees > httpFetchSaveMerchantPayeeByHandleId: Failed to save payee with handle ${handleId}`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            onError()
            return false
        }
    }
}

export const fetchSubscribeToMerchantPayeeByHandleId = (handleId="", onLoad=()=>{}) => {
    /**
      * Purpose: retrieve one payee from the firestore database by its handle id
      * Note: the onSnapshot below watches for changes on the server
      */
    if (!handleId) return () => {}
    const firestore = getFirestore(firebaseApp)
    const payeesRef = query(collection(firestore, "payees"), 
                                where("handleId", "==", handleId),
                                where("payeeType", "==", PAYEE_TYPE_PAYDM_MERCHANT),
                                limit(1)
                                )
    return async dispatch => {
        try {
            const payeesListener = await onSnapshot(payeesRef, 
                querySnapshot => {
                    //get an array of payees from the snapshot
                    const payees = querySnapshot.docs.map(docRef => ({...docRef.data()}));
                    dispatch(savePayees(payees))
                    onLoad()
                } )
            return payeesListener
        } catch (e){
            const message = `action > payees > fetchSubscribeToMerchantPayeeByHandleId: Failed to save payee with handle ${handleId}`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            return ()=> {}
        }
    }
}

export const fetchSaveMerchantPayeesInList = (payeeIds=[]) => {
    /**
      * Purpose: retrieve payees from the firestore database that are in an arbitrary list   
      */
    const firestore = getFirestore(firebaseApp)
    return async (dispatch) => {
        try {
            if (!payeeIds || payeeIds.length === 0) return []
            let payees = await getFirestoreObjectsByIdList(
                firestore, 
                payeeIds, 
                "payees",
                [where("payeeType", "==", PAYEE_TYPE_PAYDM_MERCHANT)]
            )
            dispatch(savePayees(payees))
            return payees
        } catch (e){
            const message = `action > payees > fetchSaveMerchantPayeesInList: Failed to save the payees in the list ${payeeIds}`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            return false
        }
    }
}

export const fetchSaveMerchantPayeeFeeSettings = (
    payeeId,
    onSuccess=()=>{},
    onError=()=>{}
) => {
    /**
      * Purpose: retrieve a merchant payee's fee settings from the firestore database
      */
    const firestore = getFirestore(firebaseApp)
    const feeSettingsRef = doc(firestore, `payees/${payeeId}/feeSettings`, PAYEE_TRANSACTION_FEE_SETTINGS_ID)
    
    return async dispatch => {
        try {
            const feeSettingsDocRef = await getDoc(feeSettingsRef)
            let feeSettings
            if (feeSettingsDocRef.exists()) {
                feeSettings = {...feeSettingsDocRef.data()};
                dispatch(savePayeeFeeSettings(feeSettings, payeeId))
            } else throw new Error(`Fee settings for payee ${payeeId} does not exist`)
            onSuccess(payeeId)
            return feeSettings
        } catch (e){
            const message = `action > payees > fetchSaveMerchantPayeeFeeSettings: Failed to save fee settings for payee ${payeeId}`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            onError()
            return false
        }
    }
}