import React from "react"

import Form from "../../components/Form"
import FormField from "../../components/FormField"
import PayeeCustomerSelect from "../../containers/PayeeCustomerSelect"
import CustomerFeeCalculator from "../../containers/CustomerFeeCalculator"

import {capitalizeAllWords} from "../../utils/stringUtils"
import {validateAddress, isValidCurrencyValue} from "../../utils/formValidationUtils"
import {matchIsValidTel} from 'mui-tel-input'

import {bindActionCreators} from "redux";
import * as actions from "../../actions"
import {connect} from "react-redux"
import {INVOICE_TYPE_SIMPLE, INVOICE_STATUS_CREATED} from "../../constants/invoices"
class InvoiceForm extends Form{

    constructor(props){
        super(props)
        this.state = {
            values: {...this.fields},
            errors: {},
        }
    }

    fields = {
        totalXcd: "",
        reason: "",
        invoiceType: INVOICE_TYPE_SIMPLE,
        currentStatus: INVOICE_STATUS_CREATED,
        billToCustomer: null
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps.payeeId !== this.props.payeeId){
            this.clearFields()
        }
    }

    executeSubmit = async () => {
        const {actions, payeeId} = this.props
        const {totalXcd, reason, invoiceType, currentStatus, billToCustomer={}}= this.state.values
        const {id:payeeCustomerId, name="", address={}, email="", phoneNumber="", payeeId:payeeCustomerPayeeId="", payerId="", createdAt} = billToCustomer
        await actions.fetchCreateInvoice(
            this.generateId(),
            payeeId,
            totalXcd,
            reason,
            invoiceType,
            currentStatus,
            {id:payeeCustomerId, name, address, email, phoneNumber, payeeId:payeeCustomerPayeeId, payerId, createdAt},
            this.handleSubmitSuccess, 
            this.handleSubmitError
        )
    }

    validate = () => {
        const errors = {}
        const {transactionLimitXcd} = this.props
        const {values} = this.state
        let {totalXcd, reason, billToCustomer} = values
        reason = reason.trim()
        Object.keys(values).forEach(key => {
            if (!Boolean(values[key])){
                const label = key === "totalXcd" ? "Total" :
                              key === "billToCustomer" ? "Customer name" : 
                                capitalizeAllWords(key)
                errors[key] = `${label} is required`
            }
        })
        if (!errors["billToCustomer"] && billToCustomer){
            if (!billToCustomer.name) errors["billToCustomer"] = "Please provide a name"
            if (!billToCustomer.phoneNumber || !matchIsValidTel(billToCustomer.phoneNumber)) errors["billToCustomer"] = "Please provide a valid phone number"
            if (!billToCustomer.address || Object.keys(validateAddress(billToCustomer.address)).length > 0) errors["billToCustomer"] = "Please provide a valid address"
        }
        if (!errors["reason"] && reason.length < 3) errors["reason"] = "Please provide more detail"
        if (!errors["totalXcd"] && totalXcd <= 0) errors['totalXcd'] = 'Total must be more than zero'
        if (!errors["totalXcd"] && !isValidCurrencyValue(totalXcd)) errors["totalXcd"] = "Invalid currency total"
        if (!errors["totalXcd"] && transactionLimitXcd && Number(totalXcd) > Number(transactionLimitXcd)) errors["totalXcd"] = `Over your transaction limit $${transactionLimitXcd}. Contact Shopdm Pay team to increase`
        if (Object.values(errors).every(error => !error)) return true
        else {
            this.setState({errors})
            return false
        }
    }
    
    handleSubmitSuccess = (invoice) => {
        const {onSuccess=()=>{}} = this.props
        onSuccess(invoice.id)
    }

    render(){
        const {values, errors} = this.state
        const {totalXcd, reason, billToCustomer} = values
        const {payeeId} = this.props
        return (
            <div>
                <div className="h2 text-align-center">Get Paid</div>
                <FormField 
                    value={totalXcd} 
                    type="currency"
                    min={0}
                    onChange={value => this.handleChangeSelect(value, "totalXcd")}
                    errorText={errors.totalXcd}
                />
                <div className="margin-left-1em label">
                    <CustomerFeeCalculator payeeId={payeeId} totalXcd={totalXcd}/>
                </div>
                <FormField 
                    label="What's this for?" 
                    value={reason} 
                    onChange={e => this.handleChange(e, 'reason')}
                    onKeyDown={this.handleKeyDown}
                    errorText={errors.reason}
                />
                <PayeeCustomerSelect 
                    payeeId={payeeId}
                    defaultCustomer={billToCustomer}
                    onChange={payeeCustomer => this.handleChangeSelect(payeeCustomer, 'billToCustomer')}
                    errorText={errors.billToCustomer}
                    onKeyDown={this.handleKeyDown}
                />
                <button className="button gradient width-100pct" onClick={this.handleSubmit}>Collect Payment</button>
            </div>
        )
    }
}

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch)
});
export default connect(null, mapDispatchToProps)(InvoiceForm)