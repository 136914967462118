/**
 *  Purpose: the modifications that are carried out on the cart objects for each command
 */

import {initialState} from './index'
import * as types from '../actions'
import {logError} from "../utils/errorHandlingUtils"
import {v4 as uuid4} from 'uuid'


// STATE
// carts:{
//  cartsById:{},
//  cartIdByPayeeId: {}
// }
 
const cartsReducer = (state = initialState.carts, action) => {
    const {type, payload} = action;
    let cartsById = {...state.cartsById}
    let cartIdByPayeeId = {...state.cartIdByPayeeId}
    switch (type){
        case types.ADD_TO_CART: {
            if(typeof payload.item !== "object") {
                logError(`cartsReducer > ADD_TO_CART: item payload is not an object ${JSON.stringify(payload)}`)
                return state
            }
            let {payeeId=""} = payload.item || {}
            if (!payeeId) {
                logError(`cartsReducer > ADD_TO_CART: payee id is unset ${JSON.stringify(payload)}`)
                return state
            }
            if (typeof payload.payeeCatalogId !== "string") {
                logError(`cartsReducer > ADD_TO_CART: payee catalog id is not a string ${JSON.stringify(payload)}`)
                return state
            }
            //create a new cart if the cart isnt identified
            const cartId = payload.cartId ? payload.cartId : uuid4()
            const cart = cartsById[cartId] || {id: cartId, payeeId}
            //index cart id by payee, to allow it to be retrieved for a payee's shop
            cartIdByPayeeId[payeeId] = cartId
            //if the "add to cart" passed, zero then remove the item from cart
            if (payload.item.quantity <= 0 ){
                const removed = removeFromCart(payload.item.id, cartId, cartsById, cartIdByPayeeId)
                return {
                    ...state,
                    cartsById: removed.cartsById,
                    cartIdByPayeeId: removed.cartIdByPayeeId
                }
            }
            return {
                ...state,
                cartsById: {
                    ...cartsById,
                    [cartId]: {
                        ...cart,
                        lineItemsById: {
                            ...cart.lineItemsById,
                            [payload.item.id]: {...payload.item}
                        },
                        payeeCatalogId: payload.payeeCatalogId
                    }
                },
                cartIdByPayeeId
            }
        }

        case types.REMOVE_FROM_CART: {
            if(typeof payload.itemId !== "string") {
                logError(`cartReducer > REMOVE_FROM_CART: itemId in payload is not a string ${JSON.stringify(payload)}`)
                return state
            }
            if(typeof payload.cartId !== "string") {
                logError(`cartReducer > REMOVE_FROM_CART: cartId in payload is not a string ${JSON.stringify(payload)}`)
                return state
            }
            const removed = removeFromCart(payload.itemId, payload.cartId, cartsById, cartIdByPayeeId)
            return {
                ...state,
                cartsById: removed.cartsById,
                cartIdByPayeeId: removed.cartIdByPayeeId
            }
        }

        case types.CLEAR_CART: {
            if(typeof payload.cartId !== "string") {
                logError(`cartsReducer > CLEAR_CART: cartId in payload is not a string ${JSON.stringify(payload)}`)
                return state
            }
            const cart = cartsById[payload.cartId]
            const {payeeId=""} = cart || {}
            //remove the index from the payee to the cart
            if (cartIdByPayeeId[payeeId] === payload.cartId) delete cartIdByPayeeId[payeeId]
            //delete the cart
            delete cartsById[payload.cartId]
            return {
                ...state,
                cartsById,
                cartIdByPayeeId
            }
        
        }

        case types.CREATE_PAYEE_ORDER: {
            if(typeof payload.payeeOrder !== "object") {
                return state
            }
            const {cartId=""} = payload.payeeOrder
            if (!cartsById[cartId]) {
                logError(`cartReducer > CREATE_PAYEE_ORDER: cartId ${cartId} in payee order ${payload.payeeOrder.id} does not exist ${JSON.stringify(payload)}`)
            }
            return {
                ...state,
                cartsById: {
                    ...cartsById,
                    [cartId]: {
                        ...cartsById[cartId],
                        currentPayeeOrderId: payload.payeeOrder.id
                    }
                },
            }
        }
        
        default: return state
    }

}

const removeFromCart = (itemId="", cartId="", cartsById={}, cartIdByPayeeId={}) => {
    let cart = cartsById[cartId]
    const lineItemsById = cart.lineItemsById ? {...cart.lineItemsById} : {}
    const item = {...lineItemsById[itemId]}
    delete lineItemsById[itemId]
    //if there are no more items in the cart, delete it
    if (Object.keys(lineItemsById).length === 0 ){
        //remove index of this card by the payee 
        if (cartIdByPayeeId[item.payeeId] === cart.id) delete cartIdByPayeeId[item.payeeId]
        //delete the empty cart
        delete cartsById[cartId]
        cart = {}
    } 
    //otherwise update items by id to exclude the removed item
    else cart.lineItemsById = lineItemsById
    return {
        cartsById,
        cartIdByPayeeId
    }
}

export default cartsReducer