import React from "react"

import Modal from "../Modal"
import DropdownMenu from "../../components/DropdownMenu";
import IconButton from "../IconButton"
import RadioButton from "../RadioButton"

import {v4 as uuid4} from 'uuid'


class CRUDList extends React.PureComponent {

    /**
     * 
     * Purpose:
     * - Allow the creation, editing and deletion of subobjects, in an objectById map
     * 
     * Features:
     * 1. List the objects as they are created, wrapped in a card
     * 2. Display a customizable "add new" button on the top right
     * 3. Display a standard menu in the top right of each object with optional edit and delete functions
     * 
     * @param {*} props 
     */
    constructor(props){
        super(props)
        this.state = {
            createModalOpen: false,
            editId: ""
        }
    }

    static defaultProps = {
        title:"Title",
        placeholder: <div className="display-flex justify-content-center padding-05em">Nothing to show</div>,
        createIcon: "add",
        createLabel: "New",
        iconColor: "var(--secondary)",
        renderItem: o => `object ${o.id}`,
        renderItemContainerClassName: "card margin-bottom-05em",
        getKeyFromItem: o => o.id,
        getCreateForm: ({handleCreate=() => {}, handleCloseCreateModal=() => {}}) => <div>Create form</div>, 
        getEditForm: ({handleEdit=() => {}, handleCloseEditModal=() => {}}) => <div>Edit form</div>, 
        objectsById: {},
        defaultObjectId: "",
        handleCreate: () => {},
        handleDelete: () => {},
        handleEdit: () => {},
        handleChangeDefault: () => {},
        handleClickAddButton: undefined,
        handleClickDeleteMenuItem: undefined, //overrides default handleDelete call
        handleClickEditMenuItem: undefined, //overrides default handleOpenEditModal call which opens a modal
        showEditMenuItem: true, //should the edit option be displayed in the dropdown menu?
        showDeleteMenuItem: true, //should the delete option be displayed in the dropdown menu? 
        sortFunction: () => 0,
        showDefaultRadioButton: true,
        showActionMenu: true
    }

    handleOpenCreateModal = async () => this.setState({createModalOpen: true})
    handleCloseCreateModal = async () => this.setState({createModalOpen: false})

    handleOpenEditModal = async editId => this.setState({editId})
    handleCloseEditModal = () => this.setState({editId: ""})

    handleCreate = async (
        object
    ) => {
        const objectId = object && object.id ? object.id : uuid4()
        let {objectsById, defaultObjectId, handleCreate} = this.props
        object = {
            ...object,
            id: objectId
        }
        objectsById = {
            ...objectsById,
            [objectId] : object
        }
        defaultObjectId = !defaultObjectId || !objectsById[defaultObjectId] ? 
                            objectId : 
                            defaultObjectId
        return handleCreate(
                    object,
                    objectsById,
                    defaultObjectId
                )
    }

    handleEdit = async (edits) => {
        const {editId} = this.state
        let {objectsById, defaultObjectId, handleEdit} = this.props
        objectsById = {...objectsById}
        //overrite existing values for this object
        const previousObject = {
            ...objectsById[editId]
        }
        const object = {
            ...previousObject,
            ...edits,
        }
        objectsById[editId] = object
        defaultObjectId = !defaultObjectId || !objectsById[defaultObjectId] ? 
                         editId : 
                         defaultObjectId
        return handleEdit (
                    object,
                    objectsById,
                    defaultObjectId,
                    previousObject
                )
    }
    
    handleDelete = objectId => {
        let {objectsById, defaultObjectId, handleDelete} = this.props
        objectsById = {...objectsById}
        const deletedObject = {...objectsById[objectId]}
        delete objectsById[objectId]
        //if the default objects is the one which was just deleted,
        //assign the default to another object in the map if there is one, else to blank 
        defaultObjectId = defaultObjectId !== objectId ?
                           defaultObjectId
                           :
                           Object.keys(objectsById).length > 0 ?
                           Object.keys(objectsById)[0]
                           :
                           ""
        return handleDelete (
                    objectId,
                    objectsById,
                    defaultObjectId,
                    deletedObject
                )
    }

    handleChangeDefault = defaultObjectId => {
        const {objectsById, handleChangeDefault} = this.props
        if (!objectsById[defaultObjectId]) return
        return handleChangeDefault (
                    defaultObjectId,
                    objectsById,
                )
    }

    render(){
        const {createModalOpen, editId} = this.state
        const {
            objectsById, defaultObjectId,
            title, placeholder, 
            createIcon, createLabel, iconColor,
            renderItem, renderItemContainerClassName, getKeyFromItem,
            getCreateForm, getEditForm,
            showDefaultRadioButton, showActionMenu,
            sortFunction,
            handleClickAddButton,
            handleClickDeleteMenuItem,
            handleClickEditMenuItem,
            showEditMenuItem,
            showDeleteMenuItem
        } = this.props
        const editObject = objectsById[editId]
        const objectList = Object.values(objectsById)
                                 .sort(sortFunction)
        //set the size of the "new" button
        const iconButtonStyle = {
            width: 30,
            height: 30
        }
        //if icon color is provided, set the "new" button background to that color, and the svg inside to white
        if (iconColor) {
            iconButtonStyle.backgroundColor = iconColor
            iconButtonStyle.color = "white"
        }
        return (
            <div>
                <div className="display-flex">
                    <div className="flex-1">
                        <div>{title}</div>
                        <div className="display-flex">
                            <div className="tableList flex-1">
                                {
                                    objectList.length > 0 ?
                                    <React.Fragment>
                                        {
                                            objectList.map(object => {
                                                //set the dropdown menu to include edit and delete actions, based on props
                                                const dropdownMenuItems = []
                                                if (showEditMenuItem) dropdownMenuItems.push(
                                                    {
                                                        label: "Edit",
                                                        onClick: handleClickEditMenuItem ? () => handleClickEditMenuItem(object.id) : () => this.handleOpenEditModal(object.id)
                                                    }
                                                )
                                                if (showDeleteMenuItem) dropdownMenuItems.push(
                                                    {
                                                        label: "Delete",
                                                        onClick: handleClickDeleteMenuItem ? () => handleClickDeleteMenuItem(object.id) : () => this.handleDelete(object.id)
                                                    }
                                                )
                                                return (
                                                    <div className={`display-flex ${renderItemContainerClassName}`} key={getKeyFromItem(object)}>
                                                        {
                                                            showDefaultRadioButton ?
                                                            <div className="display-flex align-items-center justify-content-center">
                                                                <RadioButton checked={ defaultObjectId === object.id} onChange={() => this.handleChangeDefault(object.id)}/>
                                                            </div>
                                                            :
                                                            ""
                                                        }
                                                        <div className="flex-1">{renderItem(object)}</div>
                                                        {
                                                            showActionMenu ?
                                                            <div>
                                                                <DropdownMenu 
                                                                    menuItems={dropdownMenuItems}
                                                                />
                                                            </div>
                                                            :
                                                            ""
                                                        }
                                                    </div>
                                                    )
                                            })
                                        }
                                    </React.Fragment>
                                    :
                                    <div>{placeholder}</div>
                                }
                            </div>
                            <div className="margin-left-05em">
                                <div>
                                    <IconButton 
                                        icon={createIcon}
                                        label={createLabel}
                                        onClick={handleClickAddButton ? handleClickAddButton : this.handleOpenCreateModal}
                                        buttonStyle={iconButtonStyle }
                                        containerStyle={iconColor ? {color: iconColor} : {}}

                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    createModalOpen ? 
                    <Modal
                        isOpen={createModalOpen}
                        closeModal={this.handleCloseCreateModal}
                        className="fit"
                        overlayClassName="center"
                        closeOnOverlayClick={true}
                        showCloseButton={false}
                    >
                        {
                            getCreateForm({
                                handleCreate: this.handleCreate,
                                handleCloseCreateModal: this.handleCloseCreateModal
                            })
                        }
                    </Modal>
                    :
                    null
                }
                {
                    editId ? 
                    <Modal
                        isOpen={Boolean(editId)}
                        closeModal={this.handleCloseEditModal}
                        className="fit"
                        overlayClassName="center"
                        closeOnOverlayClick={true}
                        showCloseButton={false}
                    >
                        {
                            getEditForm({
                                handleEdit: this.handleEdit,
                                handleCloseEditModal: this.handleCloseEditModal,
                                editObject
                            })
                        }
                    </Modal>
                    :
                    null
                }
            </div>
        )
    }
}

export default CRUDList