/**
 *  Purpose: the modifications that are carried out on the payeeProduct objects for each command
 */

 import {initialState} from './index'
 import * as types from '../actions'
 import {logError} from "../utils/errorHandlingUtils"
 
 // STATE
 // payeeProducts:{
 //  payeeProductsById:{},
 //  livePayeeProductIdByPayeeProductHandleId: {},
 // }
 
 
 const payeeProductsReducer = (state = initialState.payeeProducts, action) => {
     const {type, payload} = action;
     let payeeProductsById = {...state.payeeProductsById}
     let livePayeeProductIdByPayeeProductHandleId = {...state.livePayeeProductIdByPayeeProductHandleId}
     switch (type){
        case types.SAVE_PAYEE_PRODUCTS: {
            if(typeof payload.payeeProducts !== "object") {
                logError(`payeeProductsReducer > SAVE_PAYEE_PRODUCTS: payeeProducts payload is not an object ${JSON.stringify(payload)}`)
                return state
            }
            payload.payeeProducts.forEach(payeeProduct => {
                    //index product by id
                    payeeProductsById[payeeProduct.id] = payeeProduct
                    //index product by payeeId and handleId
                    const {payeeId, handleId, deleted} = payeeProduct
                    if (payeeId && handleId && !deleted){
                        if (!livePayeeProductIdByPayeeProductHandleId[payeeId]) livePayeeProductIdByPayeeProductHandleId[payeeId] = {}
                        livePayeeProductIdByPayeeProductHandleId[payeeId][handleId] = payeeProduct.id
                    } 
            })
            return {
                ...state,
                payeeProductsById,
                livePayeeProductIdByPayeeProductHandleId
            }
        }

        case types.CREATE_PAYEE_PRODUCT: {
            if(typeof payload.payeeProduct !== "object") {
                logError(`payeeProductsReducer > CREATE_PAYEE_PRODUCT: payeeProduct payload is not an object ${JSON.stringify(payload)}`)
                return state
            }
            //index product by id
            payeeProductsById[payload.payeeProduct.id] = payload.payeeProduct
            //index product by payeeId and handleId
            const {payeeId, handleId, deleted} = payload.payeeProduct
            if (payeeId && handleId && !deleted){
                if (!livePayeeProductIdByPayeeProductHandleId[payeeId]) livePayeeProductIdByPayeeProductHandleId[payeeId] = {}
                livePayeeProductIdByPayeeProductHandleId[payeeId][handleId] = payload.payeeProduct.id
            }
            return {
                ...state,
                payeeProductsById,
                livePayeeProductIdByPayeeProductHandleId
            }
        }

        case types.UPDATE_PAYEE_PRODUCTS: {
            if(typeof payload.payeeProductId !== "string") {
                logError(`payeeProductsReducer > UPDATE_PAYEE_PRODUCTS: payeeProductId in payload is not a string ${JSON.stringify(payload)}`)
                return state
            }
            if(typeof payload.update !== "object") {
                logError(`payeeProductsReducer > UPDATE_PAYEE_PRODUCTS: update in payload is not an object ${JSON.stringify(payload)}`)
                return state
            }
            const {payeeProductId, update} = payload
            
            return {
                ...state,
                payeeProductsById: {
                    ...payeeProductsById,
                    [payeeProductId]: {
                        ...payeeProductsById[payeeProductId],
                        ...update
                    }
                },
            }
        }
 
        case types.LOGOUT: {
            return initialState.payeeProducts
        }
        
        default: return state
     }
 }
 
 export default payeeProductsReducer