import React from "react"
import Icon from "../Icon"
import { countCartItems, getCartTotal } from "../../utils/cartUtils"
import styles from "./CartBadge.module.css"

const CartBadge = ({cart={}, onClick=()=>{}}) => {
    const numOfCartItems = countCartItems(cart)
    return (
        <div 
            className={`display-flex cursor-pointer align-items-center ${styles.container}`}
            onClick={onClick}
        >
            <div className="margin-right-05em font-size-24px">
                <Icon icon="shopping-cart"/>    
            </div>
            <div className="font-size-12px font-weight-600">
                {
                    numOfCartItems > 0 ?
                    <div className={`amount ${styles.total}`}>EC${Number(getCartTotal(cart).toFixed(2))}</div>
                    :
                    null
                }
                <div>{numOfCartItems} items</div>
            </div>
        </div>
    )
}

export default CartBadge