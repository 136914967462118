import { combineReducers } from 'redux';
// reducers
import user from "./user"
import system from "./system"
import invoices from "./invoices"
import payees from "./payees"
import device from "./device"
import payers from "./payers"
import cardAuthorizationRequests from "./cardAuthorizationRequests"
import oneTimePayments from "./oneTimePayments"
import payouts from "./payouts"
import payeeActivity from "./payeeActivity"
import receipts from "./receipts"
import disbursements from "./disbursements"
import payeeCustomers from "./payeeCustomers"
import payeeProducts from "./payeeProducts"
import payeeCatalogs from "./payeeCatalogs"
import carts from "./carts"
import payeeOrders from "./payeeOrders"

import {version} from "../config/release"

export const initialState = {
    user:{
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber: "",
        authenticated:false,
        lastLogin: 0,
        activePayeeAccountId: "",
    },
    system: {
        loading: false,
        loadingText: "",
        environment: null,
        version
    },
    invoices: {
        invoicesById: {}
    },
    oneTimePayments: {
        oneTimePaymentsById: {},
        createOneTimePaymentError: null,
    },
    payees: {
        payeesById: {},
        payeeIdByHandleId: {},
        feeSettingsByPayeeId: {}
    },
    device: {
        // cityName: "",
        // deviceType: "",
        // countryCode: "",
        lastSignedInUserId: "",
        // latLon: "",
        // timezone: "",
        // mobileOSType: "",
        firstVisitedAt: 0,
        firstVisitDate: "",
        lastVisitedAt: 0,
        lastVisitDate: "",
        totalVisits: 0,
        // messagingToken: "",
        // lastUpdatedMessagingToken: 0
    }, 
    cardAuthorizationRequests: {
        authRequestsById: {},
    },
    payouts: {
        payoutsById: {},
        stats: {},
        payeeStats: {}
    },
    payeeActivity: {
        payeeActivityById: {},
    },
    receipts: {
        receiptsById: {},
        payeeProductReceiptsById: {},
        lastReceiptEmail: "",
        loaded: {
            payeeProductIds: {}
        }
    },
    disbursements: {
        disbursementsById: {}
    },
    payeeCustomers: {
        payeeCustomersById: {},
        lastLoadedByPayeeId: {},
        lastUpdatedAt: 0
    },
    payers: {
        payersById: {},
        phoneNumbers: [],
        payerIdsByPhoneNumber: {},
        activeAuthenticationData: null,
    },
    payeeProducts: {
        payeeProductsById: {},
        livePayeeProductIdByPayeeProductHandleId: {}, //'live' because deleted products are not indexed here
    },
    payeeCatalogs: {
        payeeCatalogsById: {},
        payeeCatalogIdByPayeeCatalogHandleId: {},
        lastLoadedByPayeeId: {},
        monthlyViewsByPayeeCatalogId: {}
    },
    carts: {
        cartsById: {},
        cartIdByPayeeId: {}
    },
    payeeOrders: {
        payeeOrdersById: {},
        lastLoadedByPayeeId: {},
        lastUpdatedAt: 0
    }
}

const rootReducer = combineReducers({
    user,
    system,
    invoices,
    oneTimePayments,
    payees,
    payers,
    device,
    cardAuthorizationRequests,
    payouts,
    payeeActivity,
    receipts,
    disbursements,
    payeeCustomers,
    payeeProducts,
    payeeCatalogs,
    carts,
    payeeOrders
});

export default rootReducer