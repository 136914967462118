import React, { Component } from "react";
import { logError } from "../../utils/errorHandlingUtils";

const ERROR_NAME_CHUNK_LOAD = "ChunkLoadError"

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, errorMessage: "", errorName: ""};
  }

  static defaultProps = {
      errorHeading: "Something went wrong",
      errorContext: "Unspecified",
      className: ""
  }
  static getDerivedStateFromError(error) {
    const update = { hasError: true, errorMessage: error.message }
    if (error.name === ERROR_NAME_CHUNK_LOAD) {
      update.errorName = ERROR_NAME_CHUNK_LOAD
    }
    return update
  }

  componentDidCatch(error, errorInfo) {
    const { errorContext } = this.props;
    logError(`Error Boundary Caught: ${error}, Context: ${errorContext}, ${JSON.stringify(errorInfo)}`);
  }

  render() {
    const {errorHeading, className} = this.props
    if (this.state.hasError) {
      return (
        <div className={`text-align-center ${className}`}>
          <div className="h2">{errorHeading}{typeof errorHeading === "string" ? "." : ""}</div>
          <p>Error: {this.state.errorMessage}</p>
          <button className="button secondary" onClick={() => window.location.reload()}>Retry</button>
        </div>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;