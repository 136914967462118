import React, {lazy, Suspense} from "react";

import Loading from "../../components/Loading";
import { retryLazy } from "../../utils/applicationUtils";
import ErrorBoundary from "../../components/ErrorBoundary";
import RadioButton from "../../components/RadioButton";
import FormField from "../../components/FormField"

import styles from "./PayeeProductVariantForm.module.css"
import { Switch } from "@mui/material";

// Wrap your lazy loading with retry logic
const PhotoUpload = lazy(() =>
  retryLazy(() => import("../../components/PhotoUpload"))
);


const PayeeProductVariantForm = ({
    images=[],
    variantDetails="",
    price=0, 
    quantityInStock=0,
    hasLimitedStock=false,
    id="",
    isDefaultVariant=false,
    handleChange=()=>{},
    handleChangeDefaultVariant=()=>{},
    errors={}
}) => {
    const imageUrl = images && images.length > 0 && images[0]? images[0].med : ""
    return (
        <div className={`${styles.container} display-flex`}>
            <div className="display-flex align-items-center">
                <RadioButton 
                    onChange={handleChangeDefaultVariant}
                    checked={isDefaultVariant}
                />
            </div>
            <div>
                <div className={styles.mainInputs}>
                    <div className={styles.cameraInput}>
                        <ErrorBoundary
                            errorHeading="Sorry, we could not load the photo upload. Check your internet connection and retry"
                            errorContext="PayeeProductVariantForm PhotoUpload Lazy-Loading"
                        >
                            <Suspense fallback={<Loading />}>
                                <PhotoUpload
                                    defaultImageUrl={imageUrl}
                                    onChange={image => handleChange([image], "images")} 
                                    displayButton={false}
                                    containerStyle={{borderRadius: 8, height: 45, width: 45}}
                                    errorText={errors.images}
                                    compressImage={false}
                                />
                            </Suspense>
                        </ErrorBoundary>
                    </div>
                    <div>
                        <FormField 
                            value={variantDetails} 
                            label="Option details"
                            onChange={e => handleChange(e.target.value, "variantDetails")}
                            errorText={errors.variantDetails}
                        />  
                    </div>
                    <div>
                        <FormField 
                            value={price} 
                            label="Price EC$"
                            type="currency"
                            currency=""
                            currencySymbol=""
                            min={0}
                            onChange={value => handleChange(value, "price")}
                            errorText={errors.price}
                        />
                    </div>
                </div>
                <div className={styles.quantityInputs}>
                    <div className="label display-flex align-items-center">
                        <Switch 
                            checked={hasLimitedStock}
                            color="success"
                            onChange={() => handleChange(!hasLimitedStock, "hasLimitedStock")}
                        />
                        <div className="display-flex align-items-center">
                            <div className="margin-right-05em">{hasLimitedStock? "Limited quantity" : "Don't limit quantity"}</div>
                            {errors.hasLimitedStock ? <div className="errorText" >{errors.hasLimitedStock}</div> : null}
                            {
                                hasLimitedStock ?
                                <FormField 
                                    value={quantityInStock} 
                                    label="Qty in stock"
                                    onChange={e => handleChange(e.target.value, "quantityInStock")}
                                    errorText={errors.quantityInStock}
                                />
                                :
                                null
                            }
                        </div>
                    </div>
                    
                </div>
                <div className="text-align-right">
                    {
                        isDefaultVariant
                        ?
                        <div className="badge small success">default</div>
                        :
                        ""
                    }
                </div>
            </div>
        </div>
    )    
}

export default PayeeProductVariantForm
