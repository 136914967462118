import {capitalizeAllWords} from "./stringUtils";
import {
    isValidEmail, isValidLegalName, validateAddress
} from "./formValidationUtils";
import {matchIsValidTel} from "mui-tel-input";
/**
 * Function to remove the token from the payer object for usage when copying the payer from localStorage to state.
 * @param payerData
 * @returns payerData without the token property
 */
export const removeTokenFromPayer = (payerData) => {
    if (!payerData) {
        return payerData;
    }

    // eslint-disable-next-line no-unused-vars
    const {token, ...payer} = payerData;

    return payer
}

export const validatePayer = (payerData={}) => {
    let errors = {}
    const {name, email, address, phoneNumber} = payerData
    Object.keys(payerData).forEach(key => {
        if (!Boolean(payerData[key]) 
            // && key !== "email"
        ) {
            const label = key === "phoneNumber" ? "Phone number" : capitalizeAllWords(key)
            errors[key] = `${label} is required`
        }
    })
    if (!errors["name"] && !isValidLegalName(String(name).trim())) {
        errors['name'] = 'Name is invalid'
    }
    if (!errors["phoneNumber"] && !matchIsValidTel(String(phoneNumber).trim())) {
        errors['phoneNumber'] = 'Phone number is invalid'
    }
    const addressErrors = validateAddress(address)
    if (Object.keys(addressErrors).length > 0) {
        errors["address"] = Object.values(addressErrors)[0]
        errors.addressErrorObject = addressErrors
    }
    //if the email is set, ensure it is valid
    if (email) {
        if (!isValidEmail(email)) errors.email = "Email is invalid"
    }
    return {
        success: Object.keys(errors).length === 0,
        errors
    }
}