/**
 *  Purpose: the modifications that are carried on the Receipt objects for each command
 */

 import {initialState} from './index'
 import * as types from '../actions'
 import {logError} from "../utils/errorHandlingUtils"
 
 // STATE
 // receipts:{
 //   receiptsById:{},
 //   payeeProductReceiptsById: {},
 //   lastReceiptEmail: ""
 //   loaded: {}
 // }
 
 
 const receiptsReducer = (state = initialState.receipts, action) => {
     const {type, payload} = action;
     let receiptsById = {...state.receiptsById}
     let payeeProductReceiptsById = {...state.payeeProductReceiptsById}
     let loaded = {...state.loaded}
     if (!loaded.payeeProductIds) loaded.payeeProductIds = {}
     switch (type){
         case types.SAVE_RECEIPTS: {
             if(typeof payload.receipts !== "object") {
                 logError(`receiptsReducer > SAVE_RECEIPTS: receipts payload is not an object ${JSON.stringify(payload)}`)
                 return state
             }
             payload.receipts.forEach(receipt => {
                receiptsById[receipt.id] = receipt
             });
             return {
                 ...state,
                 receiptsById,
             }
         }

         case types.SAVE_RECEIPTS_FOR_PAYEE_PRODUCT: {
             //note, unusually these receipts are kept separately from the core receipts in receiptsById
             //this is because, they belong to the payee's customers, rather than the current user.
             //they are only used for verification of payee product purchases, so they are stored in  payeeProductReceiptsById
            if(typeof payload.receipts !== "object") {
                logError(`receiptsReducer > SAVE_RECEIPTS_FOR_PAYEE_PRODUCT: receipts payload is not an object ${JSON.stringify(payload)}`)
                return state
            }
            if(typeof payload.payeeProductId !== "string") {
                logError(`receiptsReducer > SAVE_RECEIPTS_FOR_PAYEE_PRODUCT: payeeProductId payload is not a string ${JSON.stringify(payload)}`)
                return state
            }
            //
            payload.receipts.forEach(receipt => {
                if (!receipt) return
                //get the line items from the receipt
                const {lineItemsById={}} = receipt 
                const lineItemsList = Object.values(lineItemsById)
                //if there is at least one line item with the specified payeeProductId, 
                //then this receipt should be indexed
                if (
                    lineItemsList.length === 0 ||
                    //TODO in future, just check if receipt.payeeProductIds includes the secified payeeProductId
                    !lineItemsList.find(lineItem => lineItem.payeeProductId === payload.payeeProductId)
                ) return
                payeeProductReceiptsById[receipt.id] = receipt
            });
            //mark the last time that the receipts were loaded
            //if the the flag is set to update the last loaded
            //store the last loaded, last created and last updated times
            if (payload.updateLastLoaded && payload.receipts.length > 0){
                let lastCreatedAt = 0 //the most recently created receipt
                let lastUpdatedAt = 0 //the most recently updated receipt
                Object.values(payeeProductReceiptsById).forEach(receipt => {
                    //if after the current lastCreatedAt, then update
                    if (receipt.createdAt > lastCreatedAt) lastCreatedAt = receipt.createdAt //this is safe as its set on the server side
                    //if after the current lastUpdatedAt, then update
                    if (
                        receipt.lastUpdatedAtTimestamp &&
                        receipt.lastUpdatedAtTimestamp.toMillis &&
                        receipt.lastUpdatedAtTimestamp.toMillis() > lastUpdatedAt
                    ) lastUpdatedAt = receipt.lastUpdatedAtTimestamp.toMillis()
                })
                loaded.payeeProductIds[payload.payeeProductId] = {
                    lastLoadedAt: Date.now(),
                    lastCreatedAt,
                    lastUpdatedAt
                }
            }
            return {
                ...state,
                payeeProductReceiptsById,
                loaded
            }
        }

        case types.VERIFY_RECEIPT: {
            if(typeof payload.receiptId !== "string") {
                logError(`receiptsReducer > VERIFY_RECEIPT: receiptId payload is not a string ${JSON.stringify(payload)}`)
                return state
            }
            if(typeof payload.variantId !== "string") {
                logError(`receiptsReducer > VERIFY_RECEIPT: variantId payload is not a string ${JSON.stringify(payload)}`)
                return state
            }
            if(typeof payload.verification !== "object") {
                logError(`receiptsReducer > VERIFY_RECEIPT: verification payload is not an object ${JSON.stringify(payload)}`)
                return state
            }
            const receipt = payeeProductReceiptsById[payload.receiptId]
            if (! receipt || !receipt.lineItemsById) {
                logError(`receiptsReducer > VERIFY_RECEIPT: receipt ${payload.receiptId} has the wrong format`)
                return state
            }
            const lineItem = receipt.lineItemsById[payload.variantId] || {}
            const {verificationsById={}} = lineItem
            return {
                ...state,
                payeeProductReceiptsById: {
                    ...payeeProductReceiptsById,
                    [receipt.id]: {
                        ...receipt,
                        lineItemsById: {
                            ...receipt.lineItemsById,
                            [payload.variantId]: {
                                ...lineItem,
                                verificationsById: {
                                    ...verificationsById,
                                    [payload.verification.id]: payload.verification
                                }
                            }
                        }
                    } 
                }
            }
        }
         case types.SET_LAST_RECEIPT_EMAIL: {
            if(typeof payload.lastReceiptEmail !== "string") {
                logError(`receiptsReducer > SET_LAST_RECEIPT_EMAIL: lastReceiptEmail payload is not a string ${JSON.stringify(payload)}`)
                return state
            }
            return {
                ...state,
                lastReceiptEmail: payload.lastReceiptEmail
            }
        }

        case types.LOGOUT: {
            //clear out tge payee product receipts when the user signs out
            return {
                ...state,
                payeeProductReceiptsById: initialState.receipts.payeeProductReceiptsById,
                loaded: initialState.receipts.loaded
            }
        }
         default: return state
     }
 }
 
 export default receiptsReducer