import React, {useState, useRef} from "react";
import Icon from "../Icon";
import Menu from '@mui/material/Menu';
import MenuItem from "@mui/material/MenuItem";

import styles from './DropdownMenu.module.css'

const DropdownMenu = ({children, menuItems = [], MenuHeader = null, MenuIcon = null}) => {
    const [dropdownMenuOpen, setDropdownMenuOpen] = useState(false)
    const anchorRef = useRef();

    return (
        <React.Fragment>
            <div className={styles.container} onClick={() => setDropdownMenuOpen(true)}>
                {children}
                <span ref={anchorRef} className={styles.iconContainer}>
                    {
                        MenuIcon || 
                    <div className="iconContainer transparent">
                    <Icon icon={dropdownMenuOpen ? "keyboard-arrow-up" : "keyboard-arrow-down"}/>
                    </div>
                    }
                </span>
            </div>
            <Menu
                open={dropdownMenuOpen}
                onClose={() => setDropdownMenuOpen(false)}
                anchorEl={dropdownMenuOpen ? anchorRef.current : null}
                disableScrollLock={true}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right"
                }}
            >
                {MenuHeader ? <MenuHeader/> : null}
                {
                    menuItems.map(menuItem => {
                        const {label, icon, onClick = () => {}} = menuItem
                        return (
                            <MenuItem
                                key={label}
                                className={icon ? "icon-left" : ""}
                                onClick={() => {
                                    setDropdownMenuOpen(false);
                                    onClick();
                                }}>
                                {icon ? <Icon icon={icon}/> : ""}{label}
                            </MenuItem>
                        )
                    })
                }
            </Menu>
        </React.Fragment>
    )
}

export default DropdownMenu