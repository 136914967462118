import {firebaseApp} from "../config/firebase"
import { getFirestore, doc, onSnapshot, setDoc, getDoc, getDocs, query, collection, where, serverTimestamp, limit} from "firebase/firestore"
import {getStorage, ref, uploadBytes, getDownloadURL, deleteObject} from "firebase/storage"
import { logError } from "../utils/errorHandlingUtils"
import { firebaseFetch } from "../utils/firebase"
// import { PAGINATE_NEXT, PAGINATE_BACK } from "../constants/interaction"

export const SAVE_PAYEE_PRODUCTS = 'SAVE_PAYEE_PRODUCTS'
export const CREATE_PAYEE_PRODUCT = 'CREATE_PAYEE_PRODUCT'
export const UPDATE_PAYEE_PRODUCTS = 'UPDATE_PAYEE_PRODUCT'

export const savePayeeProducts = payeeProducts => {
    return {
        type: SAVE_PAYEE_PRODUCTS,
        payload: {
            payeeProducts
        }
    }
}

export const createPayeeProduct = payeeProduct => {
    return {
        type: CREATE_PAYEE_PRODUCT,
        payload: {
            payeeProduct
        }
    }
}

export const updatePayeeProducts = (payeeProductId, update={}) => {
    return {
        type: UPDATE_PAYEE_PRODUCTS,
        payload: {
            payeeProductId,
            update
        }
    }
}


export const fetchSubscribeToPayeeProduct = (payeeProductId, onLoad=()=>{}) => {
    /**
      * Purpose: retrieve one payee product from the firestore database
      * Note: the onSnapshot below watches for changes to the payee product on the server
      */
    const firestore = getFirestore(firebaseApp)
    const payeeProductRef = doc(firestore, "payeeProducts", payeeProductId)
                                
    return async (dispatch) => {
        try {
            const payeeProductListener = await onSnapshot(payeeProductRef,
                docRef => {
                    if (!docRef.exists()) {
                        onLoad()
                        return
                    }
                    //get one payee product from the snapshot
                    const payeeProduct = {...docRef.data()}
                    dispatch(savePayeeProducts([payeeProduct]))
                    onLoad(payeeProduct)
                } 
            )
            return payeeProductListener
        } catch (e){
            const message = `action > payeeProducts > fetchSubscribeToPayeeProducts: Failed to subscribe to payee product`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            return () => {}
        }
    }
}

export const fetchSubscribeToPayeeProductByHandleId = (
    payeeProductHandleId = "",
    payeeId = "",
    onLoad = () => {
    }
) => {
    /**
     * Purpose: retrieve one payee product via its handleId from the firestore database
     * Note: the onSnapshot below watches for changes on the server
     */
    if (!payeeProductHandleId || !payeeId) return () => {
    }
    const firestore = getFirestore(firebaseApp)
    const payeeProductsRef = query(collection(firestore, "payeeProducts"), 
                                where("handleId", "==", payeeProductHandleId),
                                where("payeeId", "==", payeeId),
                                where("deleted", "==", false),
                                limit(1)
                                )
    return async dispatch => {
        try {
            const payeeProductsListener = await onSnapshot(payeeProductsRef, 
                querySnapshot => {
                    //get an array of payee products from the snapshot
                    const payeeProducts = querySnapshot.docs.map(docRef => ({...docRef.data()}));
                    dispatch(savePayeeProducts(payeeProducts))
                    onLoad()
                } )
            return payeeProductsListener
        } catch (e) {
            const message = `action > payeeProducts > fetchSubscribeToPayeeProductByHandleId: Failed to save payee product with handle ${payeeProductHandleId} from payee ${payeeId}`
            if (e.message_) {
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            return () => {
            }
        }
    }
}

export const httpFetchSavePayeeProductByHandleId = (
    payeeProductHandleId = "",
    payeeId = "",
    onLoad = () => {
    }
) => {
    /**
     * Purpose: retrieve one payee product via its handleId from the firestore database
     */
     console.time(`httpFetchSavePayeeProductByHandleId ${payeeProductHandleId}`)
    if (!payeeProductHandleId || !payeeId) return false
    return async dispatch => {
        try {
            const payeeProducts = await firebaseFetch(
                "payeeProducts", 
                [
                    ["handleId", "==", payeeProductHandleId],
                    ["payeeId", "==", payeeId],
                    ["deleted", "==", false]
                ],
                {limit: 1}
            )
            console.timeEnd(`httpFetchSavePayeeProductByHandleId ${payeeProductHandleId}`)
            dispatch(savePayeeProducts(payeeProducts))
            onLoad()
            return true
        } catch (e) {
            const message = `action > payeeProducts > httpFetchSavePayeeProductByHandleId: Failed to save payee product with handle ${payeeProductHandleId} from payee ${payeeId}`
            if (e.message_) {
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            return false
        }
    }
}
// export const fetchSavePayeesPaginatedPayeeProducts = (
//     payeeId,
//     firstDoc=null,
//     lastDoc=null,
//     paginateDirection=PAGINATE_NEXT,
//     pageNumber=0,
//     sortBy="createdAt",
//     sortDirection="desc",
//     productCount=30
//  ) => {
//     /**
//       * Purpose: - retrieve the payee's payeeProducts from the firestore database in batches of 30
//       *          - create successive queries that take the next or previous 30 products
//       */
//     const firestore = getFirestore(firebaseApp)

//     //1. form the base query which pulls payeeProducts for the specified payee
//     //and sorts them by most recent first 
//     let batchQuery = query(collection(firestore, "payeeProducts"),
//                                     where("payeeId", "==", payeeId),
//                                      orderBy(sortBy, sortDirection)
//                                      )
//     //2. add limit and document marker to the query, depending on whether
//     //   we are going forwards or backwards, and whether this is the first query
    
//     //offset limits are used when the side is loaded on the paginated page 
//     //and the page number is MORE than 0. Our response is to use limit to load all of the documents
//     //leading up to the desired page
//     let offsetLimit = 0
//     if (paginateDirection === PAGINATE_NEXT){
//         //(a) if forwards

//         //if this is not the first query, add the document marker as the last doc from the last query
//         if (lastDoc) batchQuery = query(batchQuery, startAfter(lastDoc))
//         //limit the query size
//         //if the page number is more than zero, but this is the first query
//         //e.g. if someone sends page 2 of a category to their friend
//         //we will need to load more products than we want to, since firebase's startAfter and endBefore methods force
//         //us to load from the beginning of the ordered collection
//         if (pageNumber > 0 && !lastDoc) {
//             offsetLimit = productCount * pageNumber
//             batchQuery = query(batchQuery, limit(offsetLimit + productCount))
//         } 
//         //limit to 30 docs or otherwise
//         else {
//             batchQuery = query(batchQuery, limit(productCount))
//         }
//     } else if (paginateDirection === PAGINATE_BACK && firstDoc) {
//         //(b) if backwards && we have a marker from a previous query
        
//         //use the last query's first doc as a marker, to get docs before that 
//         //and limit to 30 docs or otherwise
//         if (firstDoc) batchQuery = query(batchQuery, endBefore(firstDoc), limitToLast(productCount))
//     } 
//     else throw new Error(`pagination failed ${paginateDirection} is invalid or firstDoc or lastDoc error`)

//     return async (dispatch, getState) => {
//         try {
//             const {payeeProducts} = getState()
//             // //check whether this products were loaded recently, and if so, do not load again
//             /**
//             if (
//                 categories.loadedProductIdsByCategoryPage[categoryId] &&
//                 categories.loadedProductIdsByCategoryPage[categoryId][sortBy] &&
//                 categories.loadedProductIdsByCategoryPage[categoryId][sortBy][pageNumber]  
//             ) {
//                 const page = categories.loadedProductIdsByCategoryPage[categoryId][sortBy][pageNumber]
//                 return {
//                     success: true,
//                     productList: page.productIds.map(pId => products.productsById[pId]),
//                     firstDoc: page.firstDoc,
//                     lastDoc: page.lastDoc
//                 }
//             }
//             **/
//             const querySnapshot = await getDocs(batchQuery)

//             const loadedPayeeProducts = querySnapshot.docs
//                                                   //TODO handle mroe elegantly. 
//                                                   //This slice simply discards the extra loaded documents, causing them to the reloaded if the user goes to their pages
//                                                   //whereas, we could save them into redux if we calculate their firstDoc, lastDoc and pageNumbers and productIds 
//                                                   .slice(offsetLimit) 
//                                                   .map((docRef, i) => {
//                 //set document markers: first and last doc of the query
//                 if (i === 0) firstDoc = docRef
//                 if (i === querySnapshot.docs.length - 1) lastDoc = docRef
//                 //extract and format the product data
//                 const payeeProduct = { ...docRef.data()}
//                 return payeeProduct
//             });
//             //only save the loaded payee products if some payee products are returned
//             //this avoids internet and other loading errors
//             if (loadedPayeeProducts.length === 0) return {success: false}
//             dispatch(
//                 savePayeeProducts(loadedPayeeProducts)
//             )
//             // dispatch (
//             //     savePayeeProductsPage(
//             //         categoryId,
//             //         sortBy,
//             //         pageNumber,
//             //         categoryProducts.map(p => p.id),
//             //         firstDoc,
//             //         lastDoc
//             //     )
//             // )
//             return {
//                 success: true,
//                 objectList: loadedPayeeProducts,
//                 firstDoc,
//                 lastDoc
//             }
//         } catch (e){
//             const message = `action > products > fetchSavePayeesPaginatedPayeeProducts: Failed to save paginated payee products for payee ${payeeId}`
//             if (e.message_){
//                 //deal with firebase-specific errors
//                 logError(new Error(`${e.message} ${message}`))
//             } else {
//                 e.message = `${e.message} ${message}`
//                 logError(e)
//             }
//             return {
//                 success: false
//             }
//         }
//     }
// }

export const fetchSavePayeeProductsForPayee = (
    payeeId,
 ) => {
    const firestore = getFirestore(firebaseApp)
    const payeeProductsRef = query(
                            collection(firestore, "payeeProducts"), 
                            where("payeeId", "==", payeeId)
                        )
    return async (dispatch) => {
        try{
            const querySnapshot = await getDocs(payeeProductsRef)
            //get an array of payee activity objects from the snapshot
            const payeeProducts = querySnapshot.docs.map(docRef => ({...docRef.data()}));
            dispatch(savePayeeProducts(payeeProducts))
            return true
        } catch (e){
            const message = `action > payeeProducts > fetchSavePayeeProductsForPayee: Failed to save payee products for ${payeeId}`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            return false
        }
    }
}

export const fetchSubscribeToPayeeProductsForPayee = (
    payeeId,
 ) => {
    const firestore = getFirestore(firebaseApp)
    const payeeProductsRef = query(
                            collection(firestore, "payeeProducts"), 
                            where("payeeId", "==", payeeId), 
                        )
    return async (dispatch) => {
        try{
            const payeeProductsListener = await onSnapshot(payeeProductsRef,
                querySnapshot =>{
                    //get an array of payee products from the snapshot
                    const payeeProducts = querySnapshot.docs.map(docRef => ({...docRef.data()}));
                    dispatch(savePayeeProducts(payeeProducts))
                }
            )
            return payeeProductsListener
        } catch (e){
            const message = `action > payeeProducts > fetchSubscribeToPayeeProductsForPayee: Failed to subscribe to payeeProducts for ${payeeId}`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            return () => {}
        }
    }
}

export const httpFetchSavePayeeProductsForPayeeCatalog = (
    payeeCatalogId,
 ) => {
    console.time(`httpFetchSavePayeeProductsForPayeeCatalog ${payeeCatalogId}`)
     if (!payeeCatalogId) return false
    return async (dispatch) => {
        try{
            const payeeProducts = await firebaseFetch(
                "payeeProducts", 
                [
                    ["payeeCatalogIds", "array-contains",  payeeCatalogId]
                ],
            )
            console.timeEnd(`httpFetchSavePayeeProductsForPayeeCatalog ${payeeCatalogId}`)
            dispatch(savePayeeProducts(payeeProducts))
            return true
        } catch (e){
            const message = `action > payeeProducts > httpFetchSavePayeeProductsForPayeeCatalog: Failed to save to payeeProducts for payee catalog ${payeeCatalogId}`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            return false
        }
    }
}

export const fetchSubscribeToPayeeProductsForPayeeCatalog = (
    payeeCatalogId,
 ) => {
    const firestore = getFirestore(firebaseApp)
    const payeeProductsRef = query(
                            collection(firestore, "payeeProducts"), 
                            where("payeeCatalogIds", "array-contains", payeeCatalogId), 
                        )
    return async (dispatch) => {
        try{
            const payeeProductsListener = await onSnapshot(payeeProductsRef,
                querySnapshot =>{
                    //get an array of payee products from the snapshot
                    const payeeProducts = querySnapshot.docs.map(docRef => ({...docRef.data()}));
                    dispatch(savePayeeProducts(payeeProducts))
                }
            )
            return payeeProductsListener
        } catch (e){
            const message = `action > payeeProducts > fetchSubscribeToPayeeProductsForPayeeCatalog: Failed to subscribe to payeeProducts for payee catalog ${payeeCatalogId}`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            return () => {}
        }
    }
}

export const fetchCreatePayeeProduct = (
    id,
    payeeId,
    name,
    handleId,
    productType,
    price,
    description,
    defaultVariantId,
    hasVariants,
    variantsById,
    isInactive,
    onSuccess=()=>{}, 
    onError=()=>{}
) => {
    const firestore = getFirestore(firebaseApp);
    const payeeProductsRef = doc(firestore, "payeeProducts", id)
    const payeeProduct = {
        id,
        payeeId,
        name,
        handleId,
        productType,
        price,
        description,
        defaultVariantId,
        hasVariants,
        variantsById,
        isInactive,
        createdAt: Date.now(),
        createdAtTimestamp: serverTimestamp(),
        deleted: false
    }
    return async (dispatch, getState) => {
        try{
            const {user} = getState()
            const storage = getStorage(firebaseApp)
            //loop through each variant and upload each of it's images
            for (let variantId in variantsById) {  
                const variant = {...variantsById[variantId]}
                const {images} = variant
                //upload a small, med and large image for each image in the images array
                variant.images = await uploadVariantImages(storage, images, payeeId, id, variantId) 
                //if this is the default varaint, set the product imageUrl to the first image in the images array
                if (defaultVariantId === variantId){
                    payeeProduct.imageUrl = variant.images[0].med
                }
                variantsById[variantId] = variant
            }
            payeeProduct.createdByUserId = user.id ? user.id : null
            await setDoc(payeeProductsRef, payeeProduct)
            dispatch(createPayeeProduct(payeeProduct))
            onSuccess(payeeProduct)
            return true
        } catch (e){
            const message = `action > payeeProducts > fetchCreatePayeeProduct: Failed to create payee product ${JSON.stringify(payeeProduct)}`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            onError(payeeProduct)
            return false
        }
        
    }
}

const uploadVariantImages = async (storage, images=[], payeeId, payeeProductId, variantId) => {
    /**
     * Purpose: upload the variant images to storage
     * 
     * Approach:
     * 
     * For each image
     * 1. check whether the image includes a "file" key, this is the flag that signals a change in photo
     * 2. construct the image path 
     * . shrink the file size 3 times to make a small and medium version
     * 3. upload the 3 files to storage
     * 4. set the small, med and large keys on the image object
     */
    images = [...images]
    let compressImageFile
    try {
        const fileUtils = await import('../utils/fileUtils');
        compressImageFile = fileUtils.compressImageFile
    } catch (error){
        alert(`Failed to load image compression tool. Check your internet connection`)
        throw new Error(error)
    }
    await Promise.all(images.map(async (image, i) => {
        //having the file key signals the need to upload
        //image objects without it already have their images uploaded on the server
        if (image.file){
            const {file} = image
            const imagePath = `payee-products/${payeeId}/${payeeProductId}/${variantId}/${i}`
            const newImage = {
                large: await compressImageFile(file, 1000),
                med: await compressImageFile(file, 500),
                small: await compressImageFile(file, 100)
            }
            for (let key in newImage){
                const imageFile = newImage[key]
                const imageRef = ref(storage, `${imagePath}/${key}`)
                await uploadBytes(imageRef, imageFile)
                //save the image url to the image object 
                newImage[key] = await getDownloadURL(imageRef)
                
            }
            images[i] = newImage
        } 
    }))
    return images
} 

const deleteImages = async (storage, images=[]) => {
    /**
     * Purpose: delete the urls in each image in a list of images
     **/

    await Promise.all(images.map(async image=> {
        for (let key in image){
            const url = image[key]
            if (typeof url === "string"){
                const imageRef = ref(storage, url)
                try {
                    await deleteObject(imageRef)
                } catch (e){   
                    if (e.code === 'storage/object-not-found') {
                        console.warn(`Image does not exist: ${url}`);
                    } else {
                        alert(`Could not delete variant image: ${e}, ${url}`)
                        console.error(e)
                    }
                }
            } 
        }
    }))
    return true 
} 

export const fetchUpdatePayeeProduct = (
    id,
    update={},
    onSuccess=()=>{},
    onError=()=>{}
 ) => {
    const firestore = getFirestore(firebaseApp)
    const payeeProductsRef = doc(firestore, "payeeProducts", id)
    return async (dispatch, getState) => {
        try{
            const {user, payeeProducts} = getState()
            const previousPayeeProduct = payeeProducts.payeeProductsById[id]
            
            /** HANDLE CHANGES IN VARIANTS - START */
            //if the variants are being updated, then the images could have been changed, in which case we must
            //1. delete from storage, the images that have been removed and
            //2. upload to storage, the images that have been added
            //3. update the default product imageUrl if it has been changed
            const {variantsById} = update
            if (variantsById){
                const storage = getStorage(firebaseApp)
                const defaultVariantId = update.defaultVariantId ? update.defaultVariantId : previousPayeeProduct.defaultVariantId
                const defaultVariant = variantsById[defaultVariantId]
                if (!defaultVariant) throw new Error("Default variant not found")
                //if the product's variants have been changed
                /**1. delete from the server any removed images**/

                //1(a). get all of the current images, so we can check whether the previous images are in there 
                const currentImagesMap = Object.values(variantsById).reduce((imageMap, variant) => {
                    const {images=[]} = variant
                    images.forEach(image => {imageMap[image.med] = image})
                    return imageMap
                }, {})
                //1(b). detect whether a previously existing image has been deleted, by checking whether it is in the list of current images
                const deletedImages = Object.values(previousPayeeProduct.variantsById).reduce((deletedImages, previousVariant) => {
                    const {images: previousImages=[]} = previousVariant
                    deletedImages = [
                        ...deletedImages, 
                        //add any images that cannot be found in the map of currently existing images
                        ...previousImages.filter(image => !currentImagesMap[image.med])
                    ]
                    return deletedImages
                }, [])
                //1(c). delete the urls of each of the images from storage
                await deleteImages(storage, deletedImages)
                /**2. upload to storage, the images that have been added**/
                for (let variantId in variantsById) {  
                    const variant = {...variantsById[variantId]}
                    const {images} = variant
                    //uploadVariantImages detects any images that need to by uploaded and
                    //uploads a small, med and large image for each image in the images array
                    //then it replaces the local urls for that image with the remote storage urls
                    //it skips, and leaves in place in the array, any images that do not need to be uploaded (those needing upload have a file key)
                    variant.images = await uploadVariantImages(storage, images, previousPayeeProduct.payeeId, id, variantId) 
                    //3. update the default product imageUrl if it has been changed
                    if (defaultVariantId === variantId){
                        update.imageUrl = variant.images[0].med
                    }
                    update.variantsById[variantId] = variant
                }
            }
            /** HANDLE CHANGES IN VARIANTS - END */
            update.lastUpdatedByUserId = user.id
            update.lastUpdatedAt = Date.now()
            update.lastUpdatedAtTimestamp = serverTimestamp()
            await setDoc(payeeProductsRef, {
                ...previousPayeeProduct,
                ...update
            })
            dispatch(updatePayeeProducts(id, update))
            onSuccess(update)
            return true
        } catch (e){
            const message = `action > payeeProducts > fetchUpdatePayeeProduct: Failed to update payment product ${id} with update ${JSON.stringify(update)}`
            if (e.message_){
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            onError(update)
            return false
        }
    }
}

export const fetchConfirmPayeeProductHandleIdUnique = (
    handleId,
    payeeId,
    onSuccess=()=>{},
    onError=()=>{}
) => {
    const firestore = getFirestore(firebaseApp)
    const payeeProductHandleRef = doc(firestore, `payees/${payeeId}/payeeProductHandles`, handleId)
    return async () => {
        try {
            const payeeProductHandleDocRef = await getDoc(payeeProductHandleRef)
            return !payeeProductHandleDocRef.exists()
        } catch (e) {
            e.message = `${e.message} action > payees > fetchConfirmPayeeProductHandleIdUnique: Failed to confirm handle ${handleId} is unique`
            logError(e)
            onError(e)
            return false
        }
    }
}