import React, {useState, useEffect} from "react"
import {useParams, Navigate, NavLink} from "react-router-dom"
import { alphabeticalSort } from "../../../utils/stringUtils"
import { countCartItems, getCartTotal } from "../../../utils/cartUtils"

import Icon from "../../../components/Icon"
import Modal from "../../../components/Modal"
import ProductList from "../../../containers/ProductList"
import PayeeOrderCheckout from "../../../containers/PayeeOrderCheckout"

import {connect} from "react-redux"
import {bindActionCreators} from "redux";
import * as actions from "../../../actions"

const Cart = ({carts, payees, payeeProducts, payeeCatalogs, payeeOrders, actions}) => {
    const params = useParams()
    const [checkoutModalOpen, setCheckoutModalOpen] = useState(false)
    //find the local cart via the payee
    const {catalogHandleId, payeeHandleId} = params
    const payeeId = payees.payeeIdByHandleId[payeeHandleId]
    const payee = payees.payeesById[payeeId]
    const cartId = carts.cartIdByPayeeId[payeeId]
    const cart = carts.cartsById[cartId]
    //listen to the payee order anytime the cart's currentPayeeOrderId changes
    //this will be right after a payeeOrder is made or when the PayeeOrderCheckout is mounted, if an order already exists
    const {currentPayeeOrderId=""} = cart || {}
    const [payeeOrderListener, setPayeeOrderListener] = useState(()=>()=>{})
    const listenToPayeeOrder = async () => {
        const newPayeeOrderListener = await actions.fetchSubscribeToPayeeOrder(currentPayeeOrderId)
        setPayeeOrderListener(() => newPayeeOrderListener)
    }
    useEffect(() => {
        if (currentPayeeOrderId) listenToPayeeOrder()
        return () => {
            if (typeof payeeOrderListener === "function") payeeOrderListener()
        }
    }, [currentPayeeOrderId])
    //if there is no cart, go back to the catalog
    if (!cart) return <Navigate replace to={`/${payeeHandleId}/c/${catalogHandleId}`} />
    const {lineItemsById={}, payeeCatalogId=""} = cart || {}
    const cartTotalXcd = getCartTotal(cart)
    //payee catalogs
    const payeeCatalog = payeeCatalogs.payeeCatalogsById[payeeCatalogId]
    const {name="", minimumSpendXcd=0} = payeeCatalog || {}
    //alert the buyer that they must spend at least the minimum spend 
    //if their total is less than the mininimum spend
    //otherwise, create the order
    const handleCreateOrder = () => setCheckoutModalOpen(true)
    const {transactionLimitXcd=0} = payee || {}
    const handleClickPlaceOrder = cartTotalXcd < minimumSpendXcd ?
                                    () => alert(`You must spend at least EC$${minimumSpendXcd} to buy from the ${name} catalog.\n\n Your current total is $${Number(cartTotalXcd).toFixed(2)}`)
                                    :
                                    transactionLimitXcd && (cartTotalXcd > transactionLimitXcd) ?
                                       () =>  alert(`Your total cannot be more than $${transactionLimitXcd}`)
                                    
                                    : 
                                    () => handleCreateOrder();
    const productListInCart = Object.values(lineItemsById)
                                 .map(item => payeeProducts.payeeProductsById[item.payeeProductId])
                                 .filter(product => Boolean(product && product.name))
                                 .sort((pA, pB)=> alphabeticalSort(pA.name, pB.name))
    const currentPayeeOrder = payeeOrders.payeeOrdersById[currentPayeeOrderId]
    //redirect from the Cart page to the receipt page if the payee order's receiptId changes to be truthy
    const receiptId = currentPayeeOrder && currentPayeeOrder.receiptId ? currentPayeeOrder.receiptId : ""
    if (receiptId) {
        //delete the cart
        setTimeout(() => actions.clearCart(cartId), 100)
        return <Navigate to={`/${payeeHandleId}/r/${receiptId}`}/>
    }
    return (
        <div>
            <div className="h2 padding-1em text-align-center">Your cart</div>
            <div className="columnLayout">
                <div className="padding-1em display-flex justify-content-space-between">
                    <div>
                        <div className="margin-bottom-1em cursor-pointer hover-background-light-grey border-radius-8px padding-5px">
                            <NavLink 
                                className="display-flex align-items-center text-decoration-none color-black" 
                                to={`/${payeeHandleId}/c/${catalogHandleId}`}
                            >
                                <Icon icon="arrow-back"/> <span className="font-size-14px">Back to item list</span>
                            </NavLink>
                        </div>
                        <div className="font-weight-600">{countCartItems(cart)} items</div>
                    </div>
                    <button className="button outline">
                        <NavLink to={`/${payeeHandleId}/c/${catalogHandleId}`}>
                            Add more
                        </NavLink> 
                    </button>
                </div>
                <ProductList productList={productListInCart}/>
                <div className="spacer"></div>
            </div>
            {
                cart ? 
                <div className="fixed-bottom-bar">
                    <div className="columnLayout">
                        <div className="display-flex justify-content-space-between font-weight-600">
                            <div>Subtotal</div>
                            <div className="amount">EC ${Number(cartTotalXcd).toFixed(2)}</div>
                        </div>
                        <div className="margin-top-05em margin-bottom-05em font-size-11px text-align-justify">By continuing, you agree to share your name, billing information and item list with the merchant. You also agree to receive order updates via phone, sms and email. You agree to our Refund Policy and our Privacy Policy.</div>
                        <button 
                            className="button success"
                            //if the buyer has not met or passed the minimum spend, then remind them of the min spend
                            //otherwise, create the order
                            onClick={handleClickPlaceOrder} 
                        >
                            Place Order
                        </button>
                    </div>
                </div>
                :
                null
            }
            {/* opens checkout flow when "place order" is pressed */}
            {
                    checkoutModalOpen ? 
                    <Modal
                        isOpen={checkoutModalOpen}
                        closeModal={() => setCheckoutModalOpen(false)}
                        className="fit"
                        overlayClassName="center"
                        closeOnOverlayClick={true}
                        showCloseButton={true}
                    >
                        <PayeeOrderCheckout cartId={cartId} payeeId={payeeId}/>
                    </Modal>
                    :
                    null
                }
        </div>
    )
}

const mapStateToProps = state => ({
    payees: state.payees,
    carts: state.carts,
    payeeProducts: state.payeeProducts,
    payeeCatalogs: state.payeeCatalogs,
    payeeOrders: state.payeeOrders
})
const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(Cart)