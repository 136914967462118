import React, {useState, useEffect} from "react"
import Icon from "../../../components/Icon"

import PayeeProductTable from "../../../containers/PayeeProductTable"

import {connect} from "react-redux"
import {bindActionCreators} from "redux";
import * as actions from "../../../actions"

import {Helmet} from "react-helmet";
import { useOutletContext } from 'react-router-dom';


const PayeeProducts = ({payeeProducts, payees, actions}) => {
    const {payeeId} = useOutletContext()
    const [payeeProductsListener, setPayeeProductsListener] = useState(() => () => {})
    //listen to any payeeProducts for this payee
    const listenToPayeeProducts = async () => {
        const newPayeeProductsListener = await actions.fetchSubscribeToPayeeProductsForPayee(payeeId)
        setPayeeProductsListener(() => newPayeeProductsListener)
    }
    //listen to the payee products when the page loads and whenever the payeeId changes
    useEffect(() => {
        listenToPayeeProducts()
        return () => {
            if (typeof payeeProductsListener === "function") payeeProductsListener()
        }
    }, [payeeId])

    const payee = payees.payeesById[payeeId]
    if (!payee) return ""
    //filter to only this payee's payeeProducts
    const payeeProductsArray = Object.values(payeeProducts.payeeProductsById)
                                    .filter(payeeProduct => {
                                        return (
                                            payeeProduct.payeeId === payeeId //only this payee's products
                                            && !payeeProduct.deleted // exclude deleted products
                                        )
                                    })
    return (
        <div>
            <Helmet>
                <title>Products - Shopdm Pay </title>
            </Helmet>
            <div className="h1 icon-left"><Icon icon="shopping-cart"/> Products</div>
            <div className="margin-top-1em">
                <PayeeProductTable 
                    payeeProductsArray={payeeProductsArray}
                    title="Products"
                    payeeId={payee.id}
                    payeeHandleId={payee.handleId}
                    payeeName={payee.name}
                    transactionLimitXcd={payee.transactionLimitXcd}
                />
            </div>
        </div>
    )
}

const mapStateToProps = state => ({
    payeeProducts: state.payeeProducts,
    payees: state.payees
})

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(PayeeProducts)