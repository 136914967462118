import React from "react"
import styles from './FormField.module.css'

import Icon from "../Icon"
import CurrencyInput from "../CurrencyInput"
import PhoneInput from "../PhoneInput"
import AddressInput from "../AddressInput"
import DataSelect from "../DataSelect"

function FormField({
    value,
    onChange,
    type="text",
    min,
    max,
    errorText,
    errorObject={},
    label,
    note="",
    editable=true,
    placeholder="",
    left="",
    right="",
    selectTextOnFocus=false,
    inputIconButton="",
    inputMode=undefined,
    currency,
    currencySymbol,
    currencyInputSize="wide",
    currencyTextSize="small",
    //data select
    dataArray=[],
    pickerLabel="name",
    showMultiple=false,
    selectMultiple=false,
    allowBlank=true,
    displayTransformFunction= value => value,
    onKeyDown=()=>{},
    onFocus=()=>{},
    onBlur=()=>{},
    onClickInputIconButton=()=>{},
    error="",

}){
    return (
        <div className={`${styles.container} ${errorText ? "error" : ""}`}>
            {label ? <label className={styles.label} >{label}</label> : null}
            <div className={styles.inputContainer}>
            {left ? <span className={styles.left}>{left}</span> : null}
            {
                type === "currency" ?
                <CurrencyInput
                    className={`input ${editable ? "": "readOnly"}`}
                    readOnly={!editable}
                    value={value}
                    onChange={editable ? onChange: ()=>{}}
                    onKeyDown={onKeyDown}
                    currency={currency}
                    currencySymbol={currencySymbol}
                    inputSize={currencyInputSize}
                    textSize={currencyTextSize}
                />
                :
                type === "tel" ?
                <PhoneInput
                    value={value}
                    onChange={editable ? onChange: ()=>{}}
                    error={Boolean(errorText)}
                />
                :
                type === "address" ?
                <AddressInput
                    address={value}
                    onChange={editable ? onChange: ()=>{}}
                    errorObject={errorObject}
                />
                :
                type === "select" ?
                <DataSelect 
                    dataArray={dataArray}
                    label={pickerLabel}
                    onSelect={editable ? onChange: ()=>{}}
                    selected={value}
                    selectMultiple={selectMultiple}
                    size={showMultiple ? 5 : 1}
                    allowBlank={allowBlank}
                    transformFunction={displayTransformFunction}
                />
                :
                type === "textarea" ?
                <textarea
                    className="textarea"
                    value={value} 
                    onChange={editable ? onChange: ()=>{}}
                    onKeyDown={onKeyDown}
                    onFocus={onFocus}
                    onBlur={onBlur}
                    readOnly={!editable}
                    placeholder={placeholder}
                ></textarea>
                :
                <div className={styles.input}>
                    <input
                        className={`input ${error ? "error": ""} ${editable ? "": "readOnly"}`}
                        type={type}
                        readOnly={!editable}
                        value={value}
                        onChange={editable ? onChange: ()=>{}}
                        onKeyDown={onKeyDown}
                        min={min}
                        max={max}
                        placeholder={placeholder}
                        onFocus={selectTextOnFocus ? e => e.target.select() : onFocus}
                        onBlur={onBlur}
                        inputMode={inputMode}
                    />
                    {
                        inputIconButton ?
                        <div className={styles.iconButton} onClick={onClickInputIconButton}>
                            <Icon icon={inputIconButton}/>
                        </div>
                        :
                        null
                    }
                </div>
            }
            {right ? <span className={styles.right}>{right}</span> : null}
            </div>
            {errorText ? <div className="errorText" >{errorText}</div> : null}
            {note ? <div>{note}</div> : null}
        </div>
    )
}

export default FormField;