import React, {useState,} from "react"

import PayeeProductForm from "../PayeeProductForm"
import {v4 as uuid4} from 'uuid'

import {connect} from "react-redux"
import {bindActionCreators} from "redux";
import * as actions from "../../actions"

const CreatePayeeProductForm = ({
    payeeId,
    transactionLimitXcd,
    title="Add New Product",
    buttonText="Create Product",
    defaultValues={},
    postSubmit=()=>{}, 
    onCancel=()=>{}, 
    onSuccess=()=>{},
    actions,

}) => {
    const[payeeProductId] = useState(uuid4())
    const handleSubmit = async (
        name,
        handleId,
        productType,
        price,
        description,
        defaultVariantId,
        hasVariants,
        variantsById,
        isInactive,
        handleSubmitSuccess,
        handleSubmitError
    ) => {
        return await actions.fetchCreatePayeeProduct(
                payeeProductId,
                payeeId,
                name,
                handleId,
                productType,
                price,
                description,
                defaultVariantId,
                hasVariants,
                variantsById,
                isInactive,
                handleSubmitSuccess,
                handleSubmitError
        )
    }
    return (
        <PayeeProductForm 
            title={title}
            defaultValues={defaultValues}
            buttonText={buttonText}
            onSubmit={handleSubmit}
            postSubmit={postSubmit}
            onCancel={onCancel}
            onSuccess={onSuccess}
            actions={actions}
            payeeId={payeeId}
            transactionLimitXcd={transactionLimitXcd}
        />
    )
}

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch)
});
  
export default connect(null, mapDispatchToProps)(CreatePayeeProductForm)