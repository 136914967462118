import {projectName} from "../config/firebase"
import { query, collection, where, getDocs, Timestamp} from "firebase/firestore";

export const getFirestoreObjectsByIdList = async (firestore, ids=[], collectionName="", constraints=[]) => {
    let queries = [];
    for(let i = 0; i < ids.length; i += 10) {
    queries.push(query(
        collection(firestore, collectionName),
        where("id", "in", ids.slice(i, i + 10)),
        ...constraints
        
    ));
    }
    let docSnapshots = [];
    for(let i = 0; i < queries.length; i++) {
        docSnapshots.push(getDocs(queries[i]));
    }
    docSnapshots = await Promise.all(docSnapshots).catch(e => console.warn(e));
 
    const docs = [...new Set([].concat(...docSnapshots.map(
            (snapshot) => snapshot.docs.map(docRef => ({...docRef.data()})))))];
    return docs
}

export const firebaseFetch = async (collectionName, queryParam = undefined, options = {}) => { 
    const FIRESTORE_BASE_URL = `https://firestore.googleapis.com/v1/projects/${projectName}/databases/(default)/documents`;
    const FIRESTORE_QUERY_URL = `${FIRESTORE_BASE_URL}:runQuery`;
  
    // Construct the structured query payload dynamically
    let queryPayload = {
        structuredQuery: {
            from: [{ collectionId: collectionName }],
        },
    };
  
    if (Array.isArray(queryParam)) {
        // Handle array of tuples for multiple conditions
        queryPayload.structuredQuery.where = {
            compositeFilter: {
                op: "AND",
                filters: queryParam.map(([field, op, value]) => ({
                    fieldFilter: {
                        field: { fieldPath: field },
                        op: mapFirestoreOperator(op),
                        value: parseValue(value),
                    },
                })),
            },
        };
    } else if (typeof queryParam === "string") {
        // Handle single document fetch
        const docUrl = `${FIRESTORE_BASE_URL}/${collectionName}/${queryParam}`;
        try {
            const response = await fetch(docUrl, {
                method: "GET",
            });
  
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
  
            const data = await response.json();
            return data.fields ? parseDocument(data) : null;
        } catch (error) {
            console.error("Error fetching document:", error);
            throw error;
        }
    } else if (queryParam) {
        // Handle single tuple for a single condition
        const [field, op, value] = queryParam;
        queryPayload.structuredQuery.where = {
            fieldFilter: {
                field: { fieldPath: field },
                op: op.toUpperCase(),
                value: parseValue(value),
            },
        };
    }
  
    if (options.limit) {
        queryPayload.structuredQuery.limit = options.limit;
    }
  
    try {
        const response = await fetch(FIRESTORE_QUERY_URL, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(queryPayload),
        });
  
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
  
        const data = await response.json();
        return data
            .filter(d => d.document)
            .map(d => parseDocument(d.document));
    } catch (error) {
        console.error("Error fetching collection:", error);
        throw error;
    }
  };
  
  // Helper to parse Firestore document data into a usable JS object
  const parseDocument = (document) => {
    const fields = document.fields || {};
    return Object.fromEntries(
        Object.entries(fields).map(([key, value]) => [key, parseFirestoreValue(value)])
    );
  };
  
  // Helper to parse Firestore value formats into plain JS values
  const parseFirestoreValue = (value) => {
    if (value.stringValue !== undefined) return value.stringValue;
    if (value.integerValue !== undefined) return parseInt(value.integerValue, 10);
    if (value.doubleValue !== undefined) return value.doubleValue;
    if (value.booleanValue !== undefined) return value.booleanValue;
    if (value.timestampValue !== undefined) return convertTimestampFields(value.timestampValue);
    if (value.arrayValue !== undefined) {
        if (!value.arrayValue.values) return [] //handle empty arrays
        return value.arrayValue.values.map(parseFirestoreValue);
    }
    if (value.mapValue !== undefined) return parseDocument(value.mapValue);
    return null;
  };
  
  // Helper to create Firestore-compatible value objects
  const parseValue = (value) => {
    if (typeof value === "string") return { stringValue: value };
    if (typeof value === "number" && Number.isInteger(value)) return { integerValue: value };
    if (typeof value === "number") return { doubleValue: value };
    if (typeof value === "boolean") return { booleanValue: value };
    if (value instanceof Date) return { timestampValue: value.toISOString() };
    throw new Error("Unsupported value type");
  };
  
  const mapFirestoreOperator = (op) => {
    const operatorMap = {
      "==": "EQUAL",
      "<": "LESS_THAN",
      "<=": "LESS_THAN_OR_EQUAL",
      ">": "GREATER_THAN",
      ">=": "GREATER_THAN_OR_EQUAL",
      "!=": "NOT_EQUAL",
      "array-contains": "ARRAY_CONTAINS",
      "array-contains-any": "ARRAY_CONTAINS_ANY",
      "in": "IN",
      "not-in": "NOT_IN",
    };
    return operatorMap[op] || op; // Default to the original operator if not found
  };

  // Helper to convert Firestore timestampValue into a Timestamp-like object
  const convertTimestampFields = (timestampValue) => {
    const date = new Date(timestampValue);
    const seconds = Math.floor(date.getTime() / 1000);
    const nanoseconds = (date.getTime() % 1000) * 1e6;
    return new Timestamp(seconds, nanoseconds);
  };