import React from "react"

import PayeeOrderListItem from "../../components/PayeeOrderListItem"

import { shortenReference } from "../../utils/interfaceUtils";
import {timestampToDateString} from "../../utils/datetimeUtils"
import {alphabeticalSort} from "../../utils/stringUtils"
import { countCartItems} from "../../utils/cartUtils"

import {connect} from "react-redux"
import {bindActionCreators} from "redux";
import * as actions from "../../actions"

const PayeeOrderItemList = ({payeeOrderId="", payeeOrders}) => {
    const order = payeeOrders.payeeOrdersById[payeeOrderId]
    if (!order) return ""
    const {reference, lineItemsById={}, totalXcd=0, paidByPayerName="", paidAt=0, currentStatus=""} = order
    return (
        <div>

            <div className="margin-bottom-1em display-width-400px">
                <div className="margin-bottom-05em">
                    <div className="h1">${totalXcd} Order from {paidByPayerName}</div> 
                    <div>Reference: <span className="font-weight-600">{shortenReference(reference)}</span></div>
                    <div className="font-weight-600 margin-bottom-05em">{countCartItems(order)} items</div>
                    <div>Ordered On: <span className="font-weight-600">{timestampToDateString(paidAt, "EEE, dd MMMM 'at' t")}</span></div>
                    <div>Status: <span className="font-weight-600">{currentStatus}</span></div>
                </div>
                <div>
                    {
                        Object.values(lineItemsById)
                              .sort( (iA, iB) => alphabeticalSort(iA.name, iB.name))
                              .map( i => <PayeeOrderListItem key={i.id} item={i}/>)
                    }
                </div>
                
            </div>
        </div>
    )
}

const mapStateToProps = state => ({
    payeeOrders: state.payeeOrders,
})
const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(PayeeOrderItemList)