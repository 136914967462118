import {DateTime} from "luxon"
import { DOMINICA_TIMEZONE } from "../constants/datetime"
export const timestampToDateString = (timestamp, format='yyyy-MM-dd', useLocalTimezone=true, timezone=DOMINICA_TIMEZONE) => {
    const options = {locale: "en-US"} 
    if (!useLocalTimezone) options.zone = timezone
    return DateTime.fromMillis(timestamp, options).toFormat(format)
}

export const dateStringToTimestamp = (dateString, format='yyyy-MM-dd', useLocalTimezone=true, timezone=DOMINICA_TIMEZONE) => {
    const options = {locale: "en-US"} 
    if (!useLocalTimezone) options.zone = timezone
    return DateTime.fromFormat(dateString, format, options).toMillis()
}

export const timestampsAreOnSameDay = (t1, t2) => {
    if (typeof t1 !== "number" || typeof t2 !== "number") return false  
    const dt1 = DateTime.fromMillis(t1)
    const dt2 = DateTime.fromMillis(t2)
    return dt1.year === dt2.year &&
           dt1.month === dt2.month &&
           dt1.day === dt2.day
}

export const getDateString = (timestamp = Date.now()) => {
    const now = new Date(timestamp)
    const year = now.getFullYear()
    const month = now.getMonth()
    const day = now.getDate()
    return `${year}-${month}-${day}`
}

export const getTimestampForStartOfDay = (timestamp=Date.now(), useLocalTimezone=true, timezone=DOMINICA_TIMEZONE) => {
    const options = {locale: "en-US"} 
    if (!useLocalTimezone) options.zone = timezone
    const dt = DateTime.fromMillis(timestamp, options)
    return dt.startOf('day').ts
}

export const getTimestampForStartOfMonth = (timestamp=Date.now(), useLocalTimezone=true, timezone=DOMINICA_TIMEZONE) => {
    const options = {locale: "en-US"} 
    if (!useLocalTimezone) options.zone = timezone
    const dt = DateTime.fromMillis(timestamp, options)
    return dt.startOf('month').ts
}