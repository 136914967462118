import {getFunctions, httpsCallable} from "firebase/functions";
import {getFirestore, doc, onSnapshot} from "firebase/firestore";

import {firebaseApp} from "../config/firebase"

import { firebaseFetch } from "../utils/firebase";
import {isCallableError, isInternalCallableError, logError} from "../utils/errorHandlingUtils"

import {startPayerAuthentication, savePayer} from "./payers";
import {ONE_TIME_PAYMENT_STATUS_CREATED} from "../constants/oneTimePayments"
import {VERIFICATION_METHODS} from "../constants/verification"
import {toggleLoading} from "./system";

export const SAVE_ONE_TIME_PAYMENTS = 'SAVE_ONE_TIME_PAYMENTS'
export const CREATE_ONE_TIME_PAYMENT = 'CREATE_ONE_TIME_PAYMENT'
export const CREATE_ONE_TIME_PAYMENT_ERROR = 'CREATE_ONE_TIME_PAYMENTS_ERROR'
export const RESET_ONE_TIME_PAYMENT_CREATE_ERROR = 'RESET_ONE_TIME_PAYMENT_CREATE_ERROR'

export const saveOneTimePayments = oneTimePayments => {
    return {
        type: SAVE_ONE_TIME_PAYMENTS,
        payload: {
            oneTimePayments
        }
    }
}

export const createOneTimePayment = oneTimePayment => {
    return {
        type: CREATE_ONE_TIME_PAYMENT,
        payload: {
            oneTimePayment
        }
    }
}

export const handleOneTimePaymentCreateError = error => {
    return {
        type: CREATE_ONE_TIME_PAYMENT_ERROR,
        payload: {
            error
        }
    }
}

export const resetOneTimePaymentCreateError = () => {
    return {
        type: RESET_ONE_TIME_PAYMENT_CREATE_ERROR,
    }
}

export const callCreateOneTimePayment = (
    id,
    oneTimePaymentData,
    onSuccess = () => {
    },
) => {
    const functions = getFunctions();

    return async (dispatch, getState) => {
        //Get a token from the payee, if there is a pyee for the phone number provided

        const {
            device,
            user,
            payers: {payerIdsByPhoneNumber, payersById},
            oneTimePayments: {createOneTimePaymentError},
            payees: {payeesById}
        } = getState();
        const payerId = payerIdsByPhoneNumber[oneTimePaymentData.payerData.phoneNumber] || ""
        let {token = ""} = payersById[payerId] || {};

        if (createOneTimePaymentError) {
            // On a new attempt to create OTP always resetting the error.
            dispatch(resetOneTimePaymentCreateError());
        }

        const oneTimePayment = {
            id,
            ...oneTimePaymentData,
            payerData: {
                ...oneTimePaymentData.payerData,
                // In some rare cases the confirmSms can fail at the state of creating OTP.
                // In that case the OTPForm state might not have payerId to put into OTP object.
                // Therefore, we put a fail-safe here to access it from a store if it's missing in an OTP object.
                id: oneTimePaymentData.payerData.id || payerId
            },
            totalXcd: Number(oneTimePaymentData.totalXcd),
            currentStatus: ONE_TIME_PAYMENT_STATUS_CREATED,
            statusHistory: {
                [ONE_TIME_PAYMENT_STATUS_CREATED]: Date.now(),
            },
            closedAt: null,
            createdAt: Date.now(),
            createdByUserId: user.id ? user.id : null,
            createdByDeviceId: device.id ? device.id : null,
        };
        const payee = payeesById[oneTimePayment.payeeId]
        const payeeHandleId = payee ? payee.handleId : ""
        //Check whether the current phone number is authorized to make payments on this device
        try {
            const checkAuthResponse = await httpsCallable(functions, 'checkOrInitiatePhoneAuthorizationRequest')({
                token,
                deviceId: device.id,
                phoneNumber: oneTimePayment.payerData.phoneNumber,
                payerData: oneTimePayment.payerData,
                payeeHandleId
            });
            
            const {success=false, requiredVerificationMethods=[], payerId="", token:newToken=""} = checkAuthResponse.data
            //if the request was successful the payer in redux should be updated
            if (success){
                //NOTE: this is one of 2 places where the token is written to redux
                //NOTE: the local Payer data is what is written to redux, on verification, local payer data will have no id, 
                //      so we add in the id returned from the server
                const payerUpdate = {
                    ...oneTimePayment.payerData,
                    id: payerId
                }
                //also attach the id to the active payerData to allow the immediate upcoming call to createOneTimePayment to work
                oneTimePayment.payerData.id = payerId
                //if an unverified payer was made, a token would have been returned
                if (newToken) {
                    //store it in redux
                    payerUpdate.token = newToken
                    //also update the live token to the new token that was returned
                    token = newToken
                }
                //update or create the payer in redux
                dispatch(savePayer(payerUpdate));
            }
            //if a form of verification is required it would be specified here
            if (requiredVerificationMethods && requiredVerificationMethods.length > 0) {
                //TODO handle responses for email and document verification here
                const verificationMethod = requiredVerificationMethods[0]
                //if sms phone verification is required, display the code acceptance screen. 
                //An SMS would have already been sent
                if (verificationMethod.method === VERIFICATION_METHODS.SMS){
                    dispatch(startPayerAuthentication({
                        oneTimePayment,
                        authRequestId: verificationMethod.authRequestId,
                    }))
                }
            }
            //if the authentication was unsuccessful, short circuit before the call to create one time payment
            if (!success) {
                dispatch(toggleLoading(false))
                return false;
            }
        } catch (e) {
            // An unlikely scenario and something went completely wrong.
            // checkOrInitiatePhoneAuthorizationRequest has no expected exceptions - only {success: false} responses are expected
            if (isInternalCallableError(e)) {
                logError(
                    `${e.message} action > oneTimePayments > callCreateOneTimePayment: Failed to check authentication status deviceIdL ${device.id}, phoneNumber: ${oneTimePayment.payerPhoneNumber}`
                );
            }
            dispatch(handleOneTimePaymentCreateError(isCallableError(e) ? e : 'Unknown error'))
            dispatch(toggleLoading(false))
            return false;
        }
        //if the phone number is indeed authorized to be used on this device
        //then create the one time payment
        try {
            const createPaymentResponse = await httpsCallable(functions, 'createOneTimePayment')({
                token,
                oneTimePayment
            });

            const {success, createdOneTimePayment} = createPaymentResponse.data;
            if (!success) {
                return false;
            }
            // when the createOneTimePayment callable resolves with success: true,
            // it returns the data it stored into the store enriched with feesData needed for further payment processing
            dispatch(createOneTimePayment(createdOneTimePayment));
            onSuccess(oneTimePayment.id);
            return true;
        } catch (error) {
            // OTP creation can throw a variety of different exceptions.
            // The callable errors can vary from invalid data being sent to BE (shouldn't happen considering UI checks that) to invalid internal database state
            // All of those exceptions are logged and notified about into slack (except for exceeding the limits).
            // Internal errors would include absence of limits on payee as well as any unexpected error in a callable.
            // Therefore, only internal error will result in an additional sentry log to avoid spamming Sentry.
            if (!isCallableError(error) || isInternalCallableError(error)) {
                logError(
                    `${error.message} action > oneTimePayments > callCreateOneTimePayment: Failed to create one-time payment ${JSON.stringify(oneTimePayment)}`
                );
            }
            dispatch(handleOneTimePaymentCreateError(isCallableError(error) ? error : 'Unknown error'))
            return false;
        } finally {
            dispatch(toggleLoading(false))
        }
    };
};


export const fetchSubscribeToOneTimePayment = (
    oneTimePaymentId = "",
    onLoad = () => {
    }
) => {
    /**
     * Purpose: retrieve one one-time payment from the firestore database
     * Note: the onSnapshot below watches for changes on the server
     */
    if (!oneTimePaymentId) return () => {
    }
    const firestore = getFirestore(firebaseApp)
    const oneTimePaymentsRef = doc(firestore, "oneTimePayments", oneTimePaymentId)
    return async dispatch => {
        try {
            return await onSnapshot(oneTimePaymentsRef,
                async docRef => {
                    if (!docRef.exists()) {
                        onLoad()
                        return
                    }
                    const oneTimePayment = {...docRef.data()};
                    dispatch(saveOneTimePayments([oneTimePayment]))
                    onLoad()
                })
        } catch (e) {
            const message = `action > oneTimePayments > fetchSubscribeToOneTimePayment: Failed to save one-time payment ${oneTimePaymentId}`
            if (e.message_) {
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            return () => {
            }
        }
    }
}

export const httpFetchSaveOneTimePayment = (
    oneTimePaymentId = "",
    onLoad = () => {
    }
) => {
    /**
     * Purpose: retrieve one one-time payment from the firestore database
     */
    if (!oneTimePaymentId) return false
    return async dispatch => {
        try {
            const oneTimePayment = await firebaseFetch("oneTimePayments", oneTimePaymentId)
            if (!oneTimePayment) {
                onLoad()
                return
            }
            dispatch(saveOneTimePayments([oneTimePayment]))
            onLoad()
            return true
        } catch (e) {
            const message = `action > oneTimePayments > httpFetchSaveOneTimePayment: Failed to save one-time payment ${oneTimePaymentId}`
            if (e.message_) {
                //deal with firebase-specific errors
                logError(new Error(`${e.message} ${message}`))
            } else {
                e.message = `${e.message} ${message}`
                logError(e)
            }
            return false
        }
    }
}
