import React, {useState, useEffect} from "react"

import DataTable from "../../components/DataTable";
import RadioButton from "../../components/RadioButton";
import Icon from "../../components/Icon";

import {timestampToDateString} from "../../utils/datetimeUtils";
import {getPayeeCatalogLink} from "../../utils/payeeCatalogUtils"

import withQRScanModal from "../../hocs/withQRScanModal"

const PayeeCatalogTable = ({
                          title = "Catalogs",
                          payeeHandleId = "",
                          payeeName = "",
                          emptyText = "No Catalogs",
                          payeeCatalogsArray = [],
                          defaultSelectedId="",
                          onChangeSelectedId=()=>{},
                          setQRScanLink = () => {},
                          setPaymentRequestMessage = () => {},
                      }) => {
    const [selectedCatalogId, setSelectedCatalogId] = useState(defaultSelectedId)
    useEffect(() => {setSelectedCatalogId(defaultSelectedId)}, [defaultSelectedId])
    const handleChangeSelectedCatalogId = selectedCatalogId => {
        setSelectedCatalogId(selectedCatalogId)
        onChangeSelectedId(selectedCatalogId)
    }
    //display QR scan modal
    const handleOpenLinkModal = catalog => {
        const {name} = catalog
        setPaymentRequestMessage(`Order from ${name} by ${payeeName} on Shopdm Pay`)
        setQRScanLink(getPayeeCatalogLink(catalog.handleId, payeeHandleId))
    }
    //highlight rows as grey if the product is deactivated 
    const rowHighlightFunction = payeeCatalog => !payeeCatalog.isActive ? "var(--grey)" : ""
    return (
        <DataTable
            dataArray={payeeCatalogsArray}
            fields={[
                {
                    id: 'id',
                    header: "",
                    hideSort: true,
                    transformFunction: id => <RadioButton 
                                                checked={id === selectedCatalogId}
                                                onChange={() => handleChangeSelectedCatalogId(id)}
                                            />
                },
                {
                    id: 'name',
                    header: 'Name',
                    transformFunction: (name, id, key, catalog) =>  <div className="display-flex">
                                                    <div>
                                                        {name}
                                                    </div>
                                                    <div className={`iconContainer transparent`} onClick={() => handleOpenLinkModal(catalog)}>
                                                        <Icon icon="open-in-new"/>
                                                    </div> 
                                                </div>
                },
                // {
                //     id: 'payeeProductIds',
                //     header: 'Products',
                //     transformFunction: (payeeProductIds, id, key, payeeProduct) => <div>{payeeProductIds ? `${payeeProductIds.length} products` : ""}</div>
                // },
                {
                    id: 'views',
                    header: 'Views',
                    transformFunction: views => <div>{views? views : 0}</div>
                },
                // {
                //     id: 'createdAt',
                //     header: 'Created At',
                //     transformFunction: createdAt => timestampToDateString(createdAt, "EEE, dd MMMM 'at' t")
                // },
                
            ]}
            title={title}
            emptyText={emptyText}
            // defaultSortBy="createdAt"
            searchFields={["name"]}
            rowHighlightFunction={rowHighlightFunction}
        />
    )
}

export default withQRScanModal(PayeeCatalogTable)