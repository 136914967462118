import React, {useState, useEffect} from "react"
import Icon from "../../../components/Icon"

import PayeeCatalogTable from "../../../containers/PayeeCatalogTable"
import PayeeCatalogChart from "../../../containers/PayeeCatalogChart"

import {connect} from "react-redux"
import {bindActionCreators} from "redux";
import * as actions from "../../../actions"

import {Helmet} from "react-helmet";
import { useOutletContext } from 'react-router-dom';

const PayeeCatalogs = ({payeeCatalogs, payees, actions}) => {
    const {payeeId} = useOutletContext()

    const [selectedCatalogId, setSelectedCatalogId] = useState("")
     /**subscribe to a listener for the payee's catalogs **/

    const [payeeCatalogsListener, setPayeeCatalogsListener] = useState(() => () => {})
    //listen to any payeeCatalogs for this payee made in the specified date range
    const listenToPayeeCatalogs = async () => {
        const newPayeeCatalogsListener = await actions.fetchSubscribeToPayeeCatalogs(
            payeeId, 
        )
        setPayeeCatalogsListener(() => newPayeeCatalogsListener)
    }
    useEffect(() => {
        listenToPayeeCatalogs()
        return () => {
            if (typeof payeeCatalogsListener === "function") payeeCatalogsListener()
        }
    }, [payeeId])
    const payeeCatalogList = Object.values(payeeCatalogs.payeeCatalogsById)
                                           .filter(i => i.payeeId === payeeId)
    useEffect(() => {
        const lastCreatedCatalog = payeeCatalogList.length > 0 ? 
                                      [...payeeCatalogList].sort((pA, pB) => pB.createdAt - pA.createdAt)[0]
                                      :
                                      ""
        if (lastCreatedCatalog) setSelectedCatalogId(lastCreatedCatalog.id)
    }, [payeeCatalogs.lastLoadedByPayeeId[payeeId]])
    
    const payee = payees.payeesById[payeeId]
    if (!payee) return ""
    return (
        <div>
            <Helmet>
                <title>Catalogs - Shopdm Pay </title>
            </Helmet>
            <div className="h1 icon-left"><Icon icon="storefront"/> Catalogs</div>
            <div className="margin-top-1em">
                <PayeeCatalogChart payeeCatalogId={selectedCatalogId} payeeId={payee.id}/>
                <PayeeCatalogTable 
                    payeeCatalogsArray={payeeCatalogList}
                    title="Catalogs"
                    payeeHandleId={payee.handleId}
                    payeeName={payee.name}
                    defaultSelectedId={selectedCatalogId}
                />
            </div>
        </div>
    )
}

const mapStateToProps = state => ({
    payeeCatalogs: state.payeeCatalogs,
    payees: state.payees
})

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(PayeeCatalogs)