import currency from "currency.js"
export const countCartItems = (cart={}) => {
    const {lineItemsById={}} = cart || {}
    //add up the quantity of each item in the cart
    return Object.values(lineItemsById).reduce((sum, item) => {
        const {quantity=0} = item || {}
        sum = sum + quantity
        return sum
    }, 0)
}

export const getCartTotal = (cart={}) => {
    const {lineItemsById={}} = cart || {}
    //add up the quantity of each item in the cart
    return Object.values(lineItemsById).reduce((total, item) =>
                {
                    const {quantity=0, price=0} = item || {}
                    total = total.add(currency(price).multiply(quantity))
                    return total;
                }, currency(0)).value;
}