import React, { useState } from "react";
import Icon from "../Icon"
const ImageWithPlaceholder = ({ src, alt, containerClassName, placeholderIcon="shopping-cart" }) => {
  const [isLoading, setIsLoading] = useState(true);

  const handleImageLoad = (isLoading = false) => {
    setIsLoading(isLoading);
  };

  return (
    <div className={`position-relative width-100% height-100% ${containerClassName}`}>
        {
            isLoading && 
            (
                <div
                    className={"position-absolute inset-0 display-flex align-items-center justify-content-center background-color-light-grey color-grey font-size-32px"}
                >
                    <Icon icon={placeholderIcon} />
                </div>
            )
        }
      <img
        src={src}
        alt={alt}
        onLoad={() => handleImageLoad(false)}
        onError={() => handleImageLoad(true)}
        className={`transition-opacity transition-duration-500 ${isLoading ? "opacity-0" : "opacity-100"}`}
        style={{ display: isLoading ? "none" : "block" }}
      />
    </div>
  );
};

export default ImageWithPlaceholder;