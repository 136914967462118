import React from "react";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {matchIsValidTel} from "mui-tel-input";

import * as actions from "../../actions";

import Form from "../../components/Form";
import FormField from "../../components/FormField";
import CustomerFeeCalculator from "../../containers/CustomerFeeCalculator"
import styles from "./OneTimePaymentForm.module.css";

import {removeTokenFromPayer} from "../../utils/payer";
import {capitalizeAllWords} from "../../utils/stringUtils";
import {
    handleChangeZipCode, isValidEmail, isValidLegalName, validateAddress, isValidCurrencyValue, 
    isValidAlphaNumericAndPunctuationValue, handleChangeAlphaNumericAndPunctuationValue, sanitizeInput} from "../../utils/formValidationUtils";
import PayeeProductTotalCalculator from "../../containers/PayeeProductTotalCalculator";
import { COUNTRY_CODES } from "../../constants/country"
import { DEFAULT_PAYER_VALUES_DM } from "../../constants/payers";

const DEFAULT_OTP_VALUES = {
    reason: "",
    totalXcd: "",
    lineItemsById: {}
}

const validateTotalXcd = (totalXcd, transactionLimitXcd) => {
    let error = ""
    // Ensure input is trimmed and clean
    const totalXcdTrimmed = String(totalXcd).trim();
    if (!totalXcd || !totalXcdTrimmed) error = "Total is required" 
    if (!error && isNaN(totalXcd)) error = "Total is not a number" 
    if (!error && Number(totalXcd) <= 0) {
        error = `Must be more than zero`
    }
    if (!error && !isValidCurrencyValue(totalXcd)) error = "Invalid currency total"
    if (!error && transactionLimitXcd && Number(totalXcd) > Number(transactionLimitXcd)) {
        error = `Cannot be more than $${transactionLimitXcd}`
    }
    return {
        isValid: !Boolean(error),
        error
    }
}

const validateReason = (reason = "") => {
    let error = ""
    if (typeof reason !== "string") error = "Must be a string"
    const reasonTrimmed = reason.trim()
    if (!error && !reasonTrimmed) error = "Reason is required"
    if (!error && reasonTrimmed.length < 3) error = "Please provide more detail"
    if (!error && !isValidAlphaNumericAndPunctuationValue(reasonTrimmed)) error = "Invalid characters. Use numbers, letters and basic punctuation"
    if (!error && reasonTrimmed.length > 255) error = "Must not exceed 255 characters";
    return {
        isValid: !Boolean(error),
        error
    }
}
class OneTimePaymentForm extends Form {

    constructor(props) {
        super(props)
        //get payers indexed by phone number for easy lookup 
        const {phoneNumbers = [], payerIdsByPhoneNumber = {}, payersById = {}} = props;
        const lastUsedPhoneNumber = phoneNumbers && phoneNumbers.length > 0 ? phoneNumbers[phoneNumbers.length - 1] : null;
        const lastUsedPayerId = lastUsedPhoneNumber ? payerIdsByPhoneNumber[lastUsedPhoneNumber] : "";
        const lastUsedPayerDetails = payersById[lastUsedPayerId] || null
        //allow the parent to pass fixed values that set the total and 
        const fixedValues = {}
        const errors = {}
        if (props.fixedValues.totalXcd) {
            const sanitizedTotalXcd = sanitizeInput(props.fixedValues.totalXcd, {regex: /[^a-zA-Z0-9. \-]+|--+/g}).trim()
            const totalXcdValidation = validateTotalXcd(sanitizedTotalXcd, props.transactionLimitXcd)
            if (!totalXcdValidation.isValid) errors.totalXcd = totalXcdValidation.error
            fixedValues.totalXcd = sanitizedTotalXcd
        }
        if (props.fixedValues.reason) {
            const sanitizedReason = sanitizeInput(props.fixedValues.reason)
            const reasonValidation = validateReason(sanitizedReason)
            if (!reasonValidation.isValid) errors.reason = reasonValidation.error
            fixedValues.reason = sanitizedReason
        }
        this.state = {
            errors,
            values: {...DEFAULT_OTP_VALUES, ...fixedValues },
            arePayerDetailsDisplayed: false,
            //remove to avoid exposing the sensitive token, since one time payment can be shared
            payerData: removeTokenFromPayer(
                {...DEFAULT_PAYER_VALUES_DM, ...(lastUsedPayerDetails || {})}
            )
        }
    }

    static defaultProps = {
        fixedValues: {
            //totalXcd,
            //reason
        }
    }
    executeSubmit = async () => {
        const {actions, payeeId} = this.props
        const {values, payerData={}} = this.state
        const {id, name="", email="", phoneNumber="", address={}} = payerData
        const {reason, totalXcd, lineItemsById} = values
        await actions.callCreateOneTimePayment(
            this.generateId(),
            {
                totalXcd,
                reason,
                lineItemsById,
                payeeId,
                payerData: {
                    id,
                    name: name.trim(),
                    email: email.trim(),
                    phoneNumber,
                    address
                },
            },
            this.handleSubmitSuccess,
        )
    }

    validate = () => {
        const {transactionLimitXcd} = this.props
        const {values, payerData} = this.state
        const {name, email, address, phoneNumber} = payerData
        const {reason, totalXcd, lineItemsById} = values

        const errors = {}
        Object.keys(values).forEach(key => {
            if (
                !Boolean(values[key]) &&
                key !== "lineItemsById"
            ) {
                const label = key === "totalXcd" ? "Total" : capitalizeAllWords(key)
                errors[key] = `${label} is required`
            }
        })
        if (!errors["reason"]){
            const reasonValid = validateReason(reason)
            if (!reasonValid.isValid) errors["reason"] = reasonValid.error
        }
        if (!errors["totalXcd"]){
            const totalXcdValid = validateTotalXcd(totalXcd, transactionLimitXcd)
            if (!totalXcdValid.isValid) errors["totalXcd"] = totalXcdValid.error
        }

        Object.keys(payerData).forEach(key => {
            if (!Boolean(payerData[key]) &&
                key !== "email"
            ) {
                const label = key === "phoneNumber" ? "Phone number" : capitalizeAllWords(key)
                errors[key] = `${label} is required`
            }
        })
        if (!errors["name"] && !isValidLegalName(name.trim())) {
            errors['name'] = 'Name is invalid'
        }
        if (!errors["phoneNumber"] && !matchIsValidTel(phoneNumber.trim())) {
            errors['phoneNumber'] = 'Phone number is invalid'
        }
        const addressErrors = validateAddress(address)
        if (Object.keys(addressErrors).length > 0) {
            errors["address"] = Object.values(addressErrors)[0]
            errors.addressErrorObject = addressErrors
        }
        //if the email is set, ensure it is valid
        if (email) {
            if (!isValidEmail(email)) errors.email = "Email is invalid"
        }
        if (Object.values(errors).every(error => !error)) return true
        else {
            //if error is in payer fields and they are hidden,
            //and user attemped to submit then show the hidden fields
            let {arePayerDetailsDisplayed} = this.state
            if (!errors.phoneNumber && (errors.name || errors.address || errors.email)) arePayerDetailsDisplayed = true

            this.setState({errors, arePayerDetailsDisplayed})
            return false
        }
    }

    handleSubmitSuccess = onetimePaymentId => {
        const {
            onSuccess = () => {
            }
        } = this.props
        onSuccess(onetimePaymentId)
    }

    // Was trying to override parent handleChange and handleChangeSelect functions and call them through super,
    // but that didn't work
    handleInput = (...params) => {
        const value = handleChangeAlphaNumericAndPunctuationValue(params[0])
        if (value === undefined) return
        if (this.props.submitError) {
            this.props.actions.resetOneTimePaymentCreateError()
        }
        this.handleChange(...params)
    }

    handleSelect = (...params) => {
        if (this.props.submitError) {
            this.props.actions.resetOneTimePaymentCreateError()
        }
        this.handleChangeSelect(...params)
    }

    handleChangePayerData = (value, attribute, isInput = false) => {
        //set all of the attributes within the payerData object: phone number, name, address, email
        if (this.props.submitError) {
            this.props.actions.resetOneTimePaymentCreateError()
        }

        //make phoneNumber specific changes
        if (attribute === 'phoneNumber'){
            //if the user changes the flag, and +1767 was selected as the default
            const {phoneNumber=""} = this.state.payerData
            if (
                phoneNumber === "+1767" &&
                value !== "+1767" &&
                String(value).match(/\+(?!1)\d{1,3}767/)
            ){
                if (value.length > 3) value = value.slice(0, -3)
            }
        }

        //make address-specific changes
        if (attribute === 'address'){
            const {address={}} = this.state.payerData
            //if the user changes the city of their address, clear Roseau as the default city
            if (
                address.countryCode === "DM" &&
                String(address.city).toLowerCase() === "roseau" &&
                value.countryCode !== "DM" &&
                String(value.city).toLowerCase() === "roseau"
             ){
                 value.city = ""
             } 
             //if the user changes the zip code
             else if (value.zipCode !== address.zipCode){
                const zipCode = handleChangeZipCode(value.zipCode)
                //do not allow invalid changes
                if (zipCode === undefined) value.zipCode = address.zipCode
                else value.zipCode = zipCode
             }
        }
        this.setState((prevState) => ({
            payerData: {
                ...prevState.payerData,
                [attribute]: isInput ? String(value.target.value) : value,
            },
            errors: {}
        }))
    }

    toggleArePayerDetailsDisplayed = () => {
        this.setState((prevState) => ({
            arePayerDetailsDisplayed: !prevState.arePayerDetailsDisplayed
        }))
    }

    handlePhoneNumberEdit = (phoneNumber, phoneInfo={}) => {
        /**
         * update the phone number and retrieve a payer's details if the phone number matches an existing payer
         */
        const {payerData, arePayerDetailsDisplayed} = this.state;
        const {payerIdsByPhoneNumber, payersById} = this.props
        this.handleChangePayerData(phoneNumber, 'phoneNumber')

        const recognisedPayerId = payerIdsByPhoneNumber[phoneNumber];
        const recognisedPayer = payersById[recognisedPayerId]

        const wasRecognisedPayerId = payerIdsByPhoneNumber[payerData.phoneNumber];
        const wasRecognisedPayer = payersById[wasRecognisedPayerId]
        if (recognisedPayer) {
            this.setState({
                //remove to avoid exposing the sensitive token, since one time payment can be shared
                payerData: removeTokenFromPayer(recognisedPayer)
            })
            return;
        } else {
            //get the country code as indicated by the phone's flag input
            const {countryCode=""} = phoneInfo || {}
            const {name:countryName=""} = COUNTRY_CODES[countryCode] || {}
            const address = {...this.state.payerData.address}
            //if the country of the phone's flag input does not match
            //the currently selected country in the address, then update it
            if (
                countryCode &&
                countryName &&
                address.countryCode !== countryCode
            ){
                if (
                    address.countryCode === "DM" &&
                    address.city.toLowerCase() === "roseau"
                ){
                    address.city = ""
                }
                address.countryCode = countryCode
                address.countryName = countryName
            }
            
            this.setState((prevState) => ({
                payerData: {
                    ...prevState.payerData,
                    address
                },
                errors: {}
            }))
        }

        // If the previous phone number was a recognised payer, we want to reset the payer details we've prefilled
        if (wasRecognisedPayer && payerData.phoneNumber) {
            this.setState((prevState) => ({
                payerData: {
                    ...DEFAULT_PAYER_VALUES_DM,
                    phoneNumber: prevState.payerData.phoneNumber,
                },
            }))
        }

        if (!arePayerDetailsDisplayed) {
            this.setState({arePayerDetailsDisplayed: true})
        }
    }

    getOTPValuesFromProduct = ({
        totalXcd=0,
        reason="",
        lineItemsById={}
    }) => {
        this.setState({
            values: {
                ...this.state.values,
                totalXcd,
                reason,
                lineItemsById
            },
            errors: {}
        })
    }

    render() {
        const {
            errors,
            values: {totalXcd, reason},
            payerData: {name, email, address, phoneNumber}
        } = this.state
        const {payeeId, payeeProductId="", fixedValues={}} = this.props
        return (
            <div className={styles.container}>
                <div className={styles.section}>
                    {
                        //one time payment totals and reasons are calculated either through
                        //form inputs or a PayeeProductView
                        !payeeProductId ?
                        <React.Fragment>
                        <div className={styles.block}>
                            <FormField
                                min={0}
                                type="currency"
                                value={totalXcd}
                                errorText={errors.totalXcd}
                                onKeyDown={this.handleKeyDown}
                                onChange={value => this.handleSelect(value, "totalXcd")}
                                editable={(!fixedValues || !fixedValues.totalXcd)}
                            />
                            <div className="margin-left-1em label color-grey">
                                <CustomerFeeCalculator payeeId={payeeId} totalXcd={totalXcd} label="processing fee" left="+"/>
                            </div>
                        </div>
                        <div className={styles.block}>
                            <FormField
                                label="What's this for?"
                                value={reason}
                                errorText={errors.reason}
                                onKeyDown={this.handleKeyDown}
                                onChange={e => this.handleInput(e, 'reason')}
                                editable={(!fixedValues || !fixedValues.reason)}
                            />
                        </div>
                        </React.Fragment>
                        :
                        <div>
                            <PayeeProductTotalCalculator
                                payeeProductId={payeeProductId}
                                payeeId={payeeId}
                                onChange={({totalXcd, selectionDesc, selectedItem}) => this.getOTPValuesFromProduct({totalXcd, reason: selectionDesc, lineItemsById: selectedItem ? {[selectedItem.id]: selectedItem} : []})}
                            />
                            <div className="margin-top-05em text-align-center color-grey font-size-14px">
                                <CustomerFeeCalculator payeeId={payeeId} totalXcd={totalXcd} label="processing fee" left="+"/>
                            </div>
                            {errors.totalXcd ? <div className="errorText">{errors.totalXcd}</div> : null}
                            {errors.reason ? <div className="errorText">{errors.reason}</div> : null}
                        </div>
                    }
            
                <div className={styles.block}>
                        <FormField
                            type="tel"
                            label="Your Phone Number"
                            value={phoneNumber}
                            onKeyDown={this.handleKeyDown}
                            errorText={errors.phoneNumber}
                            onChange={this.handlePhoneNumberEdit}
                            note={
                                <div className="a label margin-top-05em">
                                    <a onClick={this.toggleArePayerDetailsDisplayed}>
                                        {this.state.arePayerDetailsDisplayed ? 'Hide Details' : 'Show Details'}
                                    </a>
                                </div>
                            }
                        />

                        {this.state.arePayerDetailsDisplayed && (
                            <>
                                <div className="h4 text-align-center margin-top-05em">Register once. Pay islandwide.</div>
                                <FormField
                                    value={name}
                                    label={"Full Name"}
                                    errorText={errors.name}
                                    onKeyDown={this.handleKeyDown}
                                    onChange={value => this.handleChangePayerData(value, "name", true)}
                                />
                                <FormField
                                    label={<div>Billing Address (must match your bank card)</div>}
                                    type="address"
                                    value={address}
                                    errorText={errors.address}
                                    onKeyDown={this.handleKeyDown}
                                    errorObject={errors.addressErrorObject}
                                    onChange={value => this.handleChangePayerData(value, 'address')}
                                />
                                <FormField
                                    type={"email"}
                                    label="Email (optional)"
                                    value={email}
                                    errorText={errors.email}
                                    onKeyDown={this.handleKeyDown}
                                    onChange={value => this.handleChangePayerData(value, "email", true)}
                                />
                            </>
                        )}
                    </div>
                    {this.props.submitError && (
                        <div className="errorText text-align-center margin-bottom-05em margin-top-05em">
                            {this.props.submitError.split('\n').map((line, index) => (
                                <React.Fragment key={index}>
                                    {line}
                                    <br/>
                                </React.Fragment>
                            ))}
                        </div>
                    )}
                </div>
                <button className="button gradient width-100pct" onClick={this.handleSubmit}>Pay Now</button>
            </div>
        )
    }
}

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch)
});

const mapStateToProps = state => ({
    phoneNumbers: state.payers.phoneNumbers,
    payerIdsByPhoneNumber: state.payers.payerIdsByPhoneNumber,
    payersById: state.payers.payersById,
    submitError: state.oneTimePayments.createOneTimePaymentError,
})
export default connect(mapStateToProps, mapDispatchToProps)(OneTimePaymentForm)