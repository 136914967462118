import React from "react"
import ProductList from "../ProductList"
import AvatarIcon from "../../components/AvatarIcon"
import CartBadge from "../../components/CartBadge"
import TextDisplayFormatter from "../../components/TextDisplayFormatter"
import { AVATAR_SIZE_MEDIUM} from "../../constants/interface"

import { countCartItems, getCartTotal } from "../../utils/cartUtils"
import { alphabeticalSort } from "../../utils/stringUtils"
import {connect} from "react-redux"

import  { useNavigate} from "react-router-dom"

const ProductCatalog = ({payeeCatalogId="", payeeId="", payeeName="", payeeLogoImageUrl="", payeeCatalogs, payeeProducts, carts}) => {
    const payeeCatalog = payeeCatalogs.payeeCatalogsById[payeeCatalogId]
    const {name="", minimumSpendXcd=0, description=""} = payeeCatalog || {}
    const catalogProductList = Object.values(payeeProducts.payeeProductsById)
                                     .filter(p => p.payeeCatalogIds && p.payeeCatalogIds.includes(payeeCatalogId))
                                     .sort((pA, pB) => alphabeticalSort(pA.name, pB.name))
                                     //TODO - sort by order provided
    const cartId = carts.cartIdByPayeeId[payeeId]
    const cart = carts.cartsById[cartId]
    const cartTotalXcd = getCartTotal(cart)
    const cartButtonLabel = `View cart (${countCartItems(cart)})`
    //navigate to the cart
    const navigate = useNavigate();
    const handleClickViewCart = () => navigate('cart', { relative: 'path' });
    return (
        <div>
            <div className="margin-auto width-fit-content display-flex align-items-center flex-direction-column">
                <div className="display-flex align-items-center">
                    <AvatarIcon name={payeeName} imageUrl={payeeLogoImageUrl} size={AVATAR_SIZE_MEDIUM}/>
                    <div className="margin-left-05em">{payeeName}</div> 
                </div>
                <div className="h2 padding-05em text-align-center">{name}</div>
                {description? <div className="font-size-14px text-align-center"><TextDisplayFormatter>{description}</TextDisplayFormatter></div> : null}
            </div>
            <div className="columnLayout">
                <div className="padding-05em display-flex justify-content-space-between align-items-center">
                    <div>
                        {
                            Number(minimumSpendXcd) > 0 ?
                            <div className="font-size-14px">Mininum spend: <span className="amount font-weight-600">EC${Number(minimumSpendXcd).toFixed(2)}</span></div>
                            :
                            null
                        }
                    </div>
                    <CartBadge cart={cart} onClick={handleClickViewCart}/>
                </div>
                <ProductList productList={catalogProductList}/>
                <div className="spacer"></div>
            </div>
            {
                cart ? 
                <div className="fixed-bottom-bar">
                    <button 
                        className="button success"
                        //navigate to cart
                        onClick={handleClickViewCart} 
                    >
                        {cartButtonLabel}
                    </button>
                </div>
                :
                null
            }
        </div>
    )
}

const mapStateToProps = state => ({
    payeeCatalogs: state.payeeCatalogs,
    payeeProducts: state.payeeProducts,
    carts: state.carts
})

export default connect(mapStateToProps)(ProductCatalog)